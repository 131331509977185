import React, { useState } from 'react'
import LoadingBar from 'react-top-loading-bar'

export default function ProgressBar({ progress, color = "red" }) {
    return (
        <LoadingBar
            color={color}
            height={7}
            progress={progress}
        // onLoaderFinished={() => setProgress(0)}
        />
    )
}
