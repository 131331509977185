import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ic_remove from "../assets/images/ic_remove.svg";
import ic_upload_document from "../assets/images/ic_upload_document.svg";
import ic_add from "../assets/images/Trasnvehicle/ic_add.svg";
import ic_delete from "../assets/images/ic_delete.svg";
import { AppContext } from "../context/user/AppContext";
import {
  postMultipartWithAuthCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
} from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import swal from "sweetalert";
import { dataURLtoFile, toBase64 } from "../fileservice/fileService";

const AddTransporterSecond = () => {
  const { customerData, mainClass, ApiCaaling, setApiCalling } =
    useContext(AppContext);
  const navigate = useNavigate();
  const [dropdownData, setDropDownData] = useState({ quantity_units: [] });
  const { state, setState } = useContext(AppContext);
  console.log(state);
  const [errMsg, setErrMsg] = useState({
    contract: "",
    origin: "",
    final_destination: "",
    weight: "",
    rate_per_20ft: "",
    rate_per_40ft: "",
    rate_per_quintal: "",
    unit: "",
  });

  useEffect(() => {
    sessionStorage.setItem("transporterData", JSON.stringify({ ...state }));
  }, [state]);

  const Register = () => {
    // if (state.contract)
    if (
      // !state.contract ||
      state.origin === "" ||
      state.final_destination === "" ||
      state.weight === "" ||
      state.rate_per_20ft === "" ||
      state.rate_per_40ft === "" ||
      state.rate_per_quintal === "" ||
      state.unit === ""
    ) {
      // if (!state.contract) {
      //   setErrMsg({ ...errMsg, contract: "Contract is requied" });
      //   window.scrollTo(0, 0);
      //   return;
      // }
      if (state.origin === "") {
        setErrMsg({ ...errMsg, origin: "Origin is required" });
        return;
      }
      if (state.final_destination === "") {
        setErrMsg({
          ...errMsg,
          final_destination: "Final Destination is required",
        });
        return;
      }
      if (state.weight === "") {
        setErrMsg({ ...errMsg, weight: "Weight is required" });
        return;
      }
      if (state.unit === "") {
        setErrMsg({ ...errMsg, unit: "Unit is required" });
        return;
      }
      if (state.rate_per_20ft === "") {
        setErrMsg({ ...errMsg, rate_per_20ft: "Rate per 20 ft is required" });
        return;
      }
      if (state.rate_per_40ft === "") {
        setErrMsg({ ...errMsg, rate_per_40ft: "Rate per 40 ft is required" });
        return;
      }
      if (state.rate_per_quintal === "") {
        setErrMsg({
          ...errMsg,
          rate_per_quintal: "Rate per Quintal ft is required",
        });
        return;
      }
    }
    const formData = new FormData();
    formData.append("company_name", state.company_name);
    formData.append("email", state.email);
    state.password && formData.append("password", state.password);
    formData.append("phone_no", state.phone_no);
    formData.append("city", state.city);
    formData.append("region", state.region);
    formData.append("country", state.country);
    formData.append("po_number", state.po_number);
    formData.append("contact_person", state.contact_person);
    formData.append(
      "contact_person_responsibility",
      state.contact_person_responsibility
    );
    formData.append("contact_person_phone", state.contact_person_phone);
    formData.append("contact_person_email", state.contact_person_email);
    formData.append("total_fleet_size", state.total_fleet_size);
    formData.append("alternate_phone", state.alternate_phone);
    formData.append("company_type", state.company_type);
    console.log(formData);

    // state.contract &&
    //   state.contract.includes("base64") &&
    //   formData.append("contract", dataURLtoFile(state.contract, "contract"));
    state.origin && formData.append("origin", state.origin);
    state.final_destination &&
      formData.append("final_destination", state.final_destination);
    state.weight && formData.append("weight", state.weight);
    state.unit && formData.append("unit", state.unit);
    state.rate_per_20ft &&
      formData.append("rate_per_20ft", state.rate_per_20ft);
    state.rate_per_40ft &&
      formData.append("rate_per_40ft", state.rate_per_40ft);
    state.rate_per_quintal &&
      formData.append("rate_per_quintal", state.rate_per_quintal);
    state.profile_picture &&
      state.profile_picture.includes("base64") &&
      formData.append(
        "profile_picture",
        dataURLtoFile(state.profile_picture, "profile_picture")
      );
    state.business_license &&
      state.business_license.includes("base64") &&
      formData.append(
        "business_license",
        dataURLtoFile(state.business_license, "business_license")
      );
    state.grade_certificate &&
      state.grade_certificate.includes("base64") &&
      formData.append(
        "grade_certificate",
        dataURLtoFile(state.grade_certificate, "grade_certificate")
      );
    state.tn_document &&
      state.tn_document.includes("base64") &&
      formData.append(
        "tn_document",
        dataURLtoFile(state.tn_document, "tn_document")
      );
    formData.append(
      "user_role",
      state.user_role ? state.user_role : "transporter"
    );
    state.id && formData.append("transporter_id", state.id);
    setApiCalling(true);
    postMultipartWithAuthCallWithErrorResponse(
      ApiConfig.ADD_TRANSPORTER,
      formData
    ).then((res) => {
      setApiCalling(false);
      swal(res.json.message);
      if (res.json.result) {
        sessionStorage.clear();

        navigate("/");
      }
    });
  };
  useEffect(() => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.ADMIN_DROPDOWN,
      JSON.stringify({ ...customerData })
    ).then((res) => {
      if (res.json.result) {
        setDropDownData(res.json);
        if (res.json.quantity_units) {
          let unit =
            res.json.quantity_units.filter(
              (unit) => unit.unit_name == state.unit
            ).length &&
            res.json.quantity_units.filter(
              (unit) => unit.unit_name == state.unit
            )[0].unit_id;
          setState({ ...state, unit: unit });
        }
      }
    });
  }, []);

  return (
    <main
      className={`main-section-AddVehicle`}
      id="cxMain"
      style={{ marginLeft: "20px" }}
    >
      <div className="main-body-AddDriver ">
        {/* Progress bar block */}
        <section className="progress-block ">
          <div className="row">
            <div className="col-12">
              <div className="main-content p-4 row">
                <div className="left col-lg-9 col-md-8">
                  <h4 className="heading-progress" id="heading-addVehicles">
                    Add Delivery System
                  </h4>
                  <div
                    className="progress"
                    style={{ height: "8px", margin: "10px", padding: "2px" }}
                  >
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "50%" }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
                <div className="right col-lg-3 col-md-4 mt-4 ">
                  <p className="progress-status">
                    Delivery System Details (2/2)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="upload-contract mt-4 d-flex justify-content-between align-items-end">
          <div>
            <label htmlFor="" className="">
              Upload Contract
            </label>

            <div className="d-flex">
              <div
                className="uploaded_file-transporter d-flex justify-content-between mt-1"
                style={{
                  backgroundColor: "#ffff",
                  border: "1px solid #D3DBDB",
                  borderRadius: "8px",
                  padding: "5px 10px",
                  minWidth: "300px",
                }}
              >
                <label
                  className="col-4 text-truncate"
                  style={{ width: "50px" }}
                >
                  {state.contract}
                </label>
                <a href="#">
                  <img
                    src={ic_remove}
                    alt=""
                    className=""
                    style={{ verticalAlign: "middle" }}
                    onClick={(e) => {
                      e.preventDefault();
                      setState({ ...state, contract: "" });
                    }}
                  />
                </a>
              </div>
              <div className="ms-2 icons">
                <input
                  type="file"
                  accept="image/*"
                  id="file"
                  onChange={(e) => {
                    setErrMsg({ ...errMsg, contract: "" });
                    let reader = new FileReader();
                    reader.onload = function (e) {
                      setState({ ...state, contract: reader.result });
                    };
                    reader.readAsDataURL(e.target.files[0]);
                  }}
                ></input>
                <label htmlFor="file">
                  <img
                    src={ic_upload_document}
                    alt=""
                    style={{ verticalAlign: "middle" }}
                  />
                </label>
              </div>
            </div>
            {errMsg.contract.length > 0 && (
              <div className="text-center text-danger">
                <span> {errMsg.contract}</span>
              </div>
            )}
          </div>
          <div className="icons upload-and-add_icon">
            <Link to="#">
              <img src={ic_add} className="add" alt="send-load-icon" />
            </Link>
          </div>
        </section> */}
        <section className="main-vehicle-info  mt-4 ">
          <div className="content " style={{ marginBottom: "80px" }}>
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Origin</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={state.origin}
                        onChange={(e) => {
                          setState({ ...state, origin: e.target.value });
                          setErrMsg({ ...errMsg, origin: "" });
                        }}
                      />
                      {errMsg.origin.length > 0 && (
                        <div className="text-center text-danger">
                          <span> {errMsg.origin}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">
                        Final Destination Point
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={state.final_destination}
                        onChange={(e) => {
                          setState({
                            ...state,
                            final_destination: e.target.value,
                          });
                          setErrMsg({ ...errMsg, final_destination: "" });
                        }}
                      />
                      {errMsg.final_destination.length > 0 && (
                        <div className="text-center text-danger">
                          <span> {errMsg.final_destination}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-6">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Weight </label>
                      <input
                        type="number"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={state.weight}
                        onChange={(e) => {
                          setState({ ...state, weight: e.target.value });
                          setErrMsg({ ...errMsg, weight: "" });
                        }}
                      />
                      {errMsg.weight.length > 0 && (
                        <div className="text-center text-danger">
                          <span> {errMsg.weight}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-6">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Unit</label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        value={state.unit}
                        onChange={(e) => {
                          setState({ ...state, unit: e.target.value });
                          setErrMsg({ ...errMsg, unit: "" });
                        }}
                      >
                        <option>Select Unit</option>
                        {dropdownData.quantity_units &&
                          dropdownData.quantity_units.length &&
                          dropdownData.quantity_units.map((unit, index) => {
                            return (
                              <option value={unit.unit_id} key={"unit" + index}>
                                {unit.unit_name}
                              </option>
                            );
                          })}
                      </select>
                      {errMsg.unit.length > 0 && (
                        <div className="text-center text-danger">
                          <span> {errMsg.unit}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">
                        Rate per 20ft Container
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={state.rate_per_20ft}
                        onChange={(e) => {
                          setState({ ...state, rate_per_20ft: e.target.value });
                          setErrMsg({ ...errMsg, rate_per_20ft: "" });
                        }}
                      />
                      {errMsg.rate_per_20ft.length > 0 && (
                        <div className="text-center text-danger">
                          <span> {errMsg.rate_per_20ft}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">
                        Rate per 40ft Container
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={state.rate_per_40ft}
                        onChange={(e) => {
                          setState({ ...state, rate_per_40ft: e.target.value });
                          setErrMsg({ ...errMsg, rate_per_40ft: "" });
                        }}
                      />
                      {errMsg.rate_per_40ft.length > 0 && (
                        <div className="text-center text-danger">
                          <span> {errMsg.rate_per_40ft}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Rate/Quintal</label>
                      <input
                        type="number"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={state.rate_per_quintal}
                        onChange={(e) => {
                          setState({
                            ...state,
                            rate_per_quintal: e.target.value,
                          });
                          setErrMsg({ ...errMsg, rate_per_quintal: "" });
                        }}
                      />
                      {errMsg.rate_per_quintal.length > 0 && (
                        <div className="text-center text-danger">
                          <span> {errMsg.rate_per_quintal}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div className="mt-4" style={{ textAlign: "right" }}>
                    <Link to="#" type="btn">
                      <img src={ic_delete} alt="" />
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="fix-bottom-bar d-flex justify-content-between align-items-center mt-4">
        <div className="left"></div>
        <div className="right">
          <a href="">
            <button type="cancel" className="cancel-btn">
              Cancel
            </button>
          </a>
          {/* <Link to="/TransporterDetails"> */}
          <button
            type="submit"
            className="continue-btn"
            id="continue-btn"
            onClick={Register}
            disabled={ApiCaaling}
          >
            Add
          </button>
          {/* </Link> */}
        </div>
      </div>
    </main>
  );
};

export default AddTransporterSecond;
