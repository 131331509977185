import React, { useState, useEffect, useContext } from "react";
import ic_dashboard_s from "../assets/images/sidebar/ic_dashboard_s.svg";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import ic_legal from "../assets/images/sidebar/ic_legal.svg";
import mgt from "../assets/images/sidebar/mgt.svg";
import ic_payment_method from "../assets/images/sidebar/ic_payment_method.svg";
import ic_reports from "../assets/images/sidebar/ic_reports.svg";
import ic_faq from "../assets/images/sidebar/ic_faq.svg";
import ic_how from "../assets/images/sidebar/ic_how.svg";
import right_arrow from "../assets/images/sidebar/right_arrow.svg";
import dropdown from "../assets/images/sidebar/dropdown.svg";
import Abay_logo from "../assets/images/sidebar/Abay_logo.png";
import ic_integration from "../assets/images/sidebar/ic_integration.svg";
import arrow_highlight from "../assets/images/sidebar/arrow_highlight.svg";
import useMediaQuery from "../assets/MediaQuery";
import { AppContext } from "../context/user/AppContext";
const Sidebar = () => {
  let isPageWide = useMediaQuery("(max-width: 768px)");
  const location = useLocation();
  let currentRoute = location.pathname;
  let hightlight = location.state ? location.state.hightlight : false;
  if (hightlight && currentRoute === "/Contracts") {
    hightlight = true;
  }

  // const [sidebar, setSidebar] = useState(isPageWide ? true:false);
  const {
    sidebar,
    setSidebar,
    mainClass,
    setMainClass,
    setMainClassB,
    customerData,
    userDetails,
  } = useContext(AppContext);
  const [dash, setDash] = useState(false);
  const [open, setOpen] = useState(false);
  const [state, setState] = useState(false);
  const [show, setShow] = useState(false);
  const [offer, setOffer] = useState(false);
  const [goods, setGoods] = useState(false);
  const [gps, setGps] = useState(false);
  const [regist, setRegist] = useState(false);
  const [online, setOnline] = useState(false);
  useEffect(() => {
    isPageWide ? setSidebar(false) : setSidebar(true);
  }, [isPageWide]);

  const showSidebar = () => {
    if (sidebar) {
      setShow(false);
      setState(false);
    }
    setSidebar(!sidebar);
  };
  function handleSidebar() {
    // setSidebar(!sidebar)
    // let cxmain = document.getElementById("cxMain");
    // let cxMainB = document.getElementById("cxMainB");
    if (!sidebar) {
      setMainClass("cx-active-sidebar");
      // if (cxmain) {
      //   cxmain.classList.add("cx-active-sidebar");
      // }
      // if (cxMainB) {
      //   cxMainB.classList.add("cx-active-sidebarB");
      // }
    } else {
      setMainClass("");
      // if (cxmain) {
      //   cxmain.classList.remove("cx-active-sidebar");
      // }
      // if (cxMainB) {
      //   cxMainB.classList.remove("cx-active-sidebarB");
      // }
    }

    if (!sidebar) {
      setMainClassB("cx-active-sidebarB ");
    } else {
      setMainClassB("");
    }
  }
  return (
    <React.Fragment>
      <div className="sidebar">
        <aside id="cx-sidebar" className={sidebar ? "cx-active" : ""}>
          {sidebar ? (
            <div className="cx-sidebar-brand">
              <div className="brandname">
                <Link className="abay text-decoration-none" to="/Dashboard">
                  <div className="d-flex brandbox">
                    <NavLink to="/Dashboard">
                      <img className="logoActive" src={Abay_logo} alt="" />
                    </NavLink>
                    <h4 className="brandActive ms-3 mt-3">Abay Logistics</h4>
                  </div>
                </Link>
              </div>
            </div>
          ) : (
            <Link to="/Dashboard">
              <img className="cx-brand-logo" src={Abay_logo} alt="" />
            </Link>
          )}
          <div
            className={`cx-sidebar-toggler ${sidebar ? "cx-active" : ""}`}
            id="cx-sidebar-toggler"
          >
            <button
              onClick={() => {
                showSidebar();
                handleSidebar();
                setOpen(!open);
              }}
              type="button"
              className="cx-toggle-btn d-flex align-items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                style={{ fill: "rgba(0, 0, 0, 1)" }}
              >
                <path d="m13.061 4.939-2.122 2.122L15.879 12l-4.94 4.939 2.122 2.122L20.121 12z"></path>
                <path d="M6.061 19.061 13.121 12l-7.06-7.061-2.122 2.122L8.879 12l-4.94 4.939z"></path>
              </svg>
            </button>
          </div>

          {/* Sidebar Menu list  */}

          <div className="list">
            <ul className={`cx-list  ${sidebar ? "cx-active" : ""}`}>
              {/* ------------------------------- Dashboard */}

              <li className="cx-nav-active">
                <NavLink
                  to="/Dashboard"
                  className="icon_arrow"
                  style={({ isActive }) => {
                    return { opacity: isActive ? "1" : "0.5" };
                  }}
                >
                  <div className="d-flex active">
                    <img src={ic_dashboard_s} alt="send-load-icon" />
                    <span>Dashboard</span>
                  </div>
                </NavLink>
                <div className="cx-tooltip">Dashboard</div>
              </li>
              {/* ---------------------------------------MGT  */}
              <li className="cx-nav-active">
                <Link
                  onClick={() => setShow(!show)}
                  to="#"
                  className={
                    `icon_arrow ` +
                    ((currentRoute === "/VehicleList" ||
                      currentRoute === "/driverlist" ||
                      currentRoute === "/GPS_Shipper" ||
                      currentRoute === "/FreightsHeaders" ||
                      currentRoute === "/SelectReportTypes") &&
                      "parentActive")
                  }
                >
                  <div className="d-flex">
                    <img
                      src={mgt}
                      alt="live-tracking-icon"
                      onClick={() => {
                        showSidebar();
                        handleSidebar();
                        setOpen(!open);
                      }}
                    />
                    <span
                      aria-controls="example-collapse-text"
                      aria-expanded={open}
                    >
                      Transport MGT.System
                    </span>
                  </div>
                  {show ? (
                    <img
                      className="Arrow_hidde  "
                      src={dropdown}
                      id="icon_Show"
                    />
                  ) : (
                    <img
                      className="Arrow_hidde "
                      src={right_arrow}
                      id="icon_Show"
                    />
                  )}
                </Link>
                <div className="cx-tooltip">Transport MGT.System</div>
              </li>
              {show && (
                <div in={open}>
                  <div
                    id="example-collapse-text"
                    className="sub-menumgt"
                    style={{ transition: "all 1s" }}
                  >
                    <ul>
                      <li className="mgt">
                        <Link
                          onClick={() => setRegist(!regist)}
                          to="#"
                          className={
                            `icon_arrow ` +
                            ((currentRoute === "/VehicleList" ||
                              currentRoute === "/driverlist") &&
                              "parentActive")
                          }
                        >
                          <div className="d-flex ">
                            <span>Listing</span>
                          </div>
                          {regist ? (
                            <img
                              className="Arrow_hidde  "
                              src={dropdown}
                              id="icon_Show"
                            />
                          ) : (
                            <img
                              className="Arrow_hidde "
                              src={right_arrow}
                              id="icon_Show"
                            />
                          )}
                        </Link>
                      </li>
                      {regist && (
                        <div>
                          <div id="example-collapse-text">
                            <ul style={{ paddingLeft: "1rem" }}>
                              <li className="good">
                                <NavLink
                                  style={({ isActive }) => {
                                    return { opacity: isActive ? "1" : "0.5" };
                                  }}
                                  to="/VehicleList"
                                  className="MGT"
                                >
                                  {" "}
                                  <img
                                    src={arrow_highlight}
                                    className="Arrow_hidde"
                                    id="icon_Show"
                                    alt="dropdown"
                                  />
                                  Vehicles
                                </NavLink>
                              </li>
                              <li className="good">
                                <NavLink
                                  style={({ isActive }) => {
                                    return { opacity: isActive ? "1" : "0.5" };
                                  }}
                                  to="/driverlist"
                                  className="MGT"
                                >
                                  {" "}
                                  <img
                                    src={arrow_highlight}
                                    className="Arrow_hidde"
                                    id="icon_Show"
                                    alt="dropdown"
                                  />
                                  Drivers
                                </NavLink>
                              </li>
                              <li className="good">
                                <NavLink to="#" className="MGT">
                                  {" "}
                                  <img
                                    src={arrow_highlight}
                                    className="Arrow_hidde"
                                    id="icon_Show"
                                    alt="dropdown"
                                  />
                                  MOU
                                </NavLink>
                              </li>
                              <li className="good">
                                <NavLink
                                  style={({ isActive }) => {
                                    return { opacity: isActive ? "1" : "0.5" };
                                  }}
                                  to="/Contracts"
                                  className="MGT"
                                >
                                  {" "}
                                  <img
                                    src={arrow_highlight}
                                    className="Arrow_hidde"
                                    id="icon_Show"
                                    alt="dropdown"
                                  />
                                  Contracts
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </div>
                      )}
                      <li className="mgt">
                        {/* <Link to="#gps" data-target="#gps" className="MGT">
                          {" "}
                          <span onClick={() => setGps(!gps)}>
                          
                           
                          </span>
                        </Link> */}
                        <Link
                          onClick={() => setGps(!gps)}
                          to="#"
                          className={
                            `icon_arrow ` +
                            ((currentRoute === "/GPS_Shipper" ||
                              currentRoute === "/FreightsHeaders" ||
                              currentRoute === "/SelectReportTypes") &&
                              "parentActive")
                          }
                        >
                          <div className="d-flex ">
                            <span>GPS Fleet Management</span>
                          </div>
                          {gps ? (
                            <img
                              className="Arrow_hidde  "
                              src={dropdown}
                              id="icon_Show"
                            />
                          ) : (
                            <img
                              className="Arrow_hidde "
                              src={right_arrow}
                              id="icon_Show"
                            />
                          )}
                        </Link>
                      </li>
                      {gps && (
                        <collapse in={goods}>
                          <div id="example-collapse-text">
                            <ul>
                              <li className="good">
                                <NavLink
                                  style={({ isActive }) => {
                                    return { opacity: isActive ? "1" : "0.5" };
                                  }}
                                  to="/GPS_Shipper"
                                  className="MGT"
                                >
                                  {" "}
                                  <img
                                    src={arrow_highlight}
                                    className="Arrow_hidde"
                                    id="icon_Show"
                                    alt="dropdown"
                                  />
                                  Live Tracking
                                </NavLink>
                              </li>
                              <li className="good">
                                <NavLink
                                  style={({ isActive }) => {
                                    return { opacity: isActive ? "1" : "0.5" };
                                  }}
                                  to="/FreightsHeaders"
                                  className="MGT"
                                >
                                  {" "}
                                  <img
                                    src={arrow_highlight}
                                    className="Arrow_hidde"
                                    id="icon_Show"
                                    alt="dropdown"
                                  />
                                  Freights
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </collapse>
                      )}
                    </ul>
                  </div>
                </div>
              )}

              {/* ----------------------------             Transport Marketplace */}
              <li className="cx-nav-active">
                <Link
                  onClick={() => setState(!state)}
                  to="#"
                  className={
                    `icon_arrow ` +
                    ((currentRoute === "/GoodsOffer" ||
                      currentRoute === "/VehicalOffRecived" ||
                      currentRoute === "/DirectVehicleRequest" ||
                      currentRoute === "/Contracts" ||
                      currentRoute === "/MarketPlaVehicleOff" ||
                      currentRoute === "/VehicalRequest" ||
                      currentRoute === "/VehicleOffer" ||
                      currentRoute === "/Cargo_Delivery" ||
                      currentRoute === "/OrderConfirmation" ||
                      currentRoute === "/TransporterOrderConfirm1") &&
                      "parentActive")
                  }
                >
                  {/*    */}

                  <div className="d-flex">
                    <img
                      src={ic_legal}
                      alt="load-icon"
                      onClick={() => {
                        showSidebar();
                        handleSidebar();
                        setOpen(!open);
                      }}
                    />
                    <span
                      aria-controls="example-collapse-text"
                      aria-expanded={state}
                    >
                      Delivery System
                    </span>
                  </div>
                  {state ? (
                    <img
                      src={dropdown}
                      className="Arrow_hidde  "
                      id="icon_Show"
                      alt="send-load-icon"
                    />
                  ) : (
                    <img
                      src={right_arrow}
                      id="icon_Show"
                      className="Arrow_hidde"
                      alt="send-load-icon"
                    />
                  )}
                </Link>
                <div className="cx-tooltip">Transport Marketplace</div>
              </li>
              {state && (
                <collapse in={open}>
                  <div id="example-collapse-text">
                    <ul>
                      {/* -----------------------------Direct order  */}
                      <li className="cx-nav-active">
                        <Link
                          onClick={() => setOffer(!offer)}
                          to="#"
                          className={
                            `icon_arrow ` +
                            ((currentRoute === "/VehicalOffRecived" ||
                              currentRoute === "/OfferGoodsDirect") &&
                              "parentActive")
                          }
                        >
                          <span>Direct Orders</span>

                          {offer ? (
                            <img
                              src={dropdown}
                              className="Arrow_hidde"
                              id="icon_Show"
                              alt="dropdown"
                            />
                          ) : (
                            <img
                              src={right_arrow}
                              className="Arrow_hidde"
                              id="icon_Show"
                              alt="Right"
                            />
                          )}
                        </Link>
                      </li>
                      {offer && (
                        <collapse in={goods}>
                          <div id="example-collapse-text">
                            <ul>
                              <li className="good">
                                <NavLink
                                  style={({ isActive }) => {
                                    return { opacity: isActive ? "1" : "0.5" };
                                  }}
                                  to="/OfferGoodsDirect"
                                  className="MGT"
                                >
                                  <img
                                    src={arrow_highlight}
                                    className="Arrow_hidde"
                                    id="icon_Show"
                                    alt="dropdown"
                                  />
                                  Offer Load
                                </NavLink>
                              </li>
                              <li className="good">
                                <NavLink
                                  style={({ isActive }) => {
                                    return { opacity: isActive ? "1" : "0.5" };
                                  }}
                                  to="/OfferedVehicles"
                                  className="MGT"
                                >
                                  <img
                                    src={arrow_highlight}
                                    className="Arrow_hidde"
                                    id="icon_Show"
                                    alt="dropdown"
                                  />
                                  Offer Vehicle
                                </NavLink>
                              </li>
                              <li className="good">
                                <NavLink
                                  style={({ isActive }) => {
                                    return { opacity: isActive ? "1" : "0.5" };
                                  }}
                                  to="/OrderConfirmation"
                                  className="MGT"
                                >
                                  {" "}
                                  <img
                                    src={arrow_highlight}
                                    className="Arrow_hidde"
                                    id="icon_Show"
                                    alt="dropdown"
                                  />
                                  Order Confirmation
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </collapse>
                      )}
                      {/* {userDetails.user_role === "transporter" && (
                        <li className="cx-nav-active">
                          <Link
                            onClick={() => setOnline(!online)}
                            to="#"
                            className={
                              `icon_arrow ` +
                              ((currentRoute ===
                                "/OrderConfirmationOnlineAuction" ||
                                currentRoute ===
                                  "/VehicleRequestOnlineAuction") &&
                                "parentActive")
                            }
                          >
                            <span>Online Auction</span>

                            {online ? (
                              <img
                                src={dropdown}
                                className="Arrow_hidde"
                                id="icon_Show"
                                alt="dropdown"
                              />
                            ) : (
                              <img
                                src={right_arrow}
                                className="Arrow_hidde"
                                id="icon_Show"
                                alt="Right"
                              />
                            )}
                          </Link>
                        </li>
                      )} */}

                      {online && (
                        <collapse in={goods}>
                          <div id="example-collapse-text">
                            <ul>
                              <li className="good">
                                <NavLink
                                  style={({ isActive }) => {
                                    return { opacity: isActive ? "1" : "0.5" };
                                  }}
                                  to="/VehicleRequestOnlineAuction"
                                  className="MGT"
                                >
                                  {" "}
                                  <img
                                    src={arrow_highlight}
                                    className="Arrow_hidde"
                                    id="icon_Show"
                                    alt="dropdown"
                                  />
                                  Offer Load
                                </NavLink>
                              </li>
                              <li className="good">
                                <NavLink
                                  style={({ isActive }) => {
                                    return { opacity: isActive ? "1" : "0.5" };
                                  }}
                                  to="/OfferedVehiclesOnline"
                                  className="MGT"
                                >
                                  <img
                                    src={arrow_highlight}
                                    className="Arrow_hidde"
                                    id="icon_Show"
                                    alt="dropdown"
                                  />
                                  Offer Vehicle
                                </NavLink>
                              </li>
                              <li className="good">
                                <NavLink
                                  style={({ isActive }) => {
                                    return { opacity: isActive ? "1" : "0.5" };
                                  }}
                                  to="/OrderConfirmationOnline"
                                  className="MGT"
                                >
                                  <img
                                    src={arrow_highlight}
                                    className="Arrow_hidde"
                                    id="icon_Show"
                                    alt="dropdown"
                                  />{" "}
                                  Order Confirmation
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </collapse>
                      )}
                    </ul>
                  </div>
                </collapse>
              )}

              {/* Reports */}
              <li className="cx-nav-active">
                <NavLink to="/Reports" className="icon_arrow">
                  <div className="d-flex ">
                    <img
                      src={ic_reports}
                      alt="send-load-icon"
                      onClick={() => {
                        showSidebar();
                        handleSidebar();
                        setOpen(!open);
                      }}
                    />
                    <span onClick={() => setDash(!dash)}>Reports</span>
                  </div>
                  {dash ? (
                    <img
                      src={dropdown}
                      className="Arrow_hidde"
                      id="icon_Show"
                      alt="send-load-icon"
                    />
                  ) : (
                    <img
                      src={right_arrow}
                      id="icon_Show"
                      className="Arrow_hidde "
                      alt="send-load-icon"
                    />
                  )}
                </NavLink>
                <div className="cx-tooltip">Reports</div>
              </li>
              {/* Payment */}
              <li className="cx-nav-active">
                <NavLink to="#" className="icon_arrow">
                  <div className="d-flex ">
                    <img
                      src={ic_payment_method}
                      alt="send-load-icon"
                      onClick={() => {
                        showSidebar();
                        handleSidebar();
                        setOpen(!open);
                      }}
                    />
                    <span onClick={() => setDash(!dash)}>Payments</span>
                  </div>
                  {dash ? (
                    <img
                      src={dropdown}
                      className="Arrow_hidde"
                      id="icon_Show"
                      alt="send-load-icon"
                    />
                  ) : (
                    <img
                      src={right_arrow}
                      id="icon_Show"
                      className="Arrow_hidde "
                      alt="send-load-icon"
                    />
                  )}
                </NavLink>
                <div className="cx-tooltip">Payments</div>
              </li>
              {/* Integration */}
              <li className="cx-nav-active">
                <NavLink to="#" className="icon_arrow">
                  <div className="d-flex ">
                    <img
                      src={ic_integration}
                      alt="send-load-icon"
                      onClick={() => {
                        showSidebar();
                        handleSidebar();
                        setOpen(!open);
                      }}
                    />
                    <span onClick={() => setDash(!dash)}>Integration</span>
                  </div>
                  {dash ? (
                    <img
                      src={dropdown}
                      className="Arrow_hidde"
                      id="icon_Show"
                      alt="send-load-icon"
                    />
                  ) : (
                    <img
                      src={right_arrow}
                      id="icon_Show"
                      className="Arrow_hidde "
                      alt="send-load-icon"
                    />
                  )}
                </NavLink>
                <div className="cx-tooltip">Integration</div>
              </li>
              {/* FAQ's  */}
              <li className="cx-nav-active">
                <NavLink
                  style={({ isActive }) => {
                    return { opacity: isActive ? "1" : "0.5" };
                  }}
                  to="/FAQ"
                  className="icon_arrow"
                >
                  <div className="d-flex">
                    <img
                      src={ic_faq}
                      alt="send-load-icon"
                      onClick={() => {
                        showSidebar();
                        handleSidebar();
                        setOpen(!open);
                      }}
                    />
                    <span>FAQ’S</span>
                  </div>
                  <img
                    src={right_arrow}
                    id="icon_Show"
                    className="Arrow_hidde"
                    alt="send-load-icon"
                  />
                </NavLink>
                <div className="cx-tooltip">FAQ’S </div>
              </li>
              {/* How its works  */}
              <li className="cx-nav-active">
                <NavLink
                  // style={({ isActive }) => {
                  //   return { opacity: isActive ? "1" : "0.5" };
                  // }}
                  to="#"
                  className="icon_arrow"
                >
                  <div className="d-flex ">
                    <img
                      src={ic_how}
                      alt="live-tracking-icon"
                      onClick={() => {
                        showSidebar();
                        handleSidebar();
                        setOpen(!open);
                      }}
                    />
                    <span>How it works? </span>
                  </div>
                  <img
                    src={right_arrow}
                    id="icon_Show"
                    className="Arrow_hidde "
                    alt="send-load-icon"
                  />
                </NavLink>
                <div className="cx-tooltip">How it works?</div>
              </li>
            </ul>
          </div>
        </aside>
      </div>
    </React.Fragment>
  );
};

export default Sidebar;
