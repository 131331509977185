import { AppContext } from "./AppContext";
import React, {
  useState
} from "react";

const AppState = (props) => {
  const [sidebar, setSidebar] = useState(false);
  const [mainClass, setMainClass] = useState("cx-active-sidebar")
  const [mainClassB, setMainClassB] = useState("cx-active-sidebarB")
  let transporterData = JSON.parse(sessionStorage.getItem("transporterData"));
  const [state, setState] = useState({
    ...transporterData
  })
  const [apiCalling, setApiCalling] = useState(false)
  const [profile, setProfile] = useState({ basic_info: { profile_pic: "" }, address: { email: "" } })
  const [dashBoardData, setDashBoardData] = useState({})
  const [notificationList, setNotificationList] = useState([]);
  const [userDetails, setUserDetails] = useState(localStorage.getItem("userDetails") ? JSON.parse(localStorage.getItem("userDetails")) : {})
  const [customerData, setCustomerData] = useState({
    customer_id: localStorage.getItem("customer_id")
      ? localStorage.getItem("customer_id")
      : null,
    user_id: localStorage.getItem("user_id")
      ? localStorage.getItem("user_id")
      : null,
    api_key: localStorage.getItem("api_key")
      ? localStorage.getItem("api_key")
      : null,

  });

  return (
    <AppContext.Provider
      value={{state, setState, notificationList, setNotificationList, mainClassB, setMainClassB, apiCalling, setApiCalling, userDetails, setUserDetails, sidebar, setSidebar, customerData, mainClass, setMainClass, setCustomerData, profile, setProfile, dashBoardData, setDashBoardData }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppState;
