import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import remove from "../../assets/images/ic_remove_red.svg";
import package_image_01 from "../../assets/images/AddVehicles/package_image_01.png";
import package_image_02 from "../../assets/images/AddVehicles/package_image_02.png";
import circle_cross from "../../assets/images/circle_cross.svg";
import ic_vehicle from "../../assets/images/AddVehicles/ic_vehicle.svg";

import { Modal, Button } from "react-bootstrap";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import ApiConfig from "../../api/ApiConfig";
import { AppContext } from "../../context/user/AppContext";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import Loader from "../../sharedComponent/Loader";
import swal from "sweetalert";
const OfferGoodsDirect = () => {
  const { mainClass } = useContext(AppContext);
  $(document).ready(function () {
    $(".datalist").DataTable();
  });
  const [loading, setLoading] = useState(false)

  const [currentLoad, setCurrentLoad] = useState({});
  const [show, setShow] = useState(false);
  const [showAccept, setShowAccept] = useState(false);
  const [fullscreenpopup, setFullscreenpopup] = useState(false);

  const [fullscreen, setFullscreen] = useState(true);

  function handleShowscreen(breakpoint) {
    setFullscreen(breakpoint);
    setFullscreenpopup(true);
  }


  const { customerData } = useContext(AppContext);
  const [specifications, setSpecifications] = useState([])
  const [vehicleRequestList, setVehicleRequestList] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleShowAccept = () => setShowAccept(true);
  const handleCloseAccept = () => setShowAccept(false);
  const handlefullscreen = () => setFullscreenpopup(true);
  const handlefullscreenClose = () => setFullscreenpopup(false);
  const navigate = useNavigate();
  useEffect(() => {
    setLoading(true)
    postWithAuthCallWithErrorResponse(ApiConfig.DRIRECT_ORDER_OFFER_GOODS, JSON.stringify({ ...customerData }))
      .then(res => {
        console.log(res);
        setLoading(false)
        setVehicleRequestList(res.json.load_list)
      })
      .catch(err => console.log("err", err))
  }, [])
  useEffect(() => {
    console.log("currentLoad", currentLoad);
    postWithAuthCallWithErrorResponse(ApiConfig.VIEWSPECIFICATION, JSON.stringify({ ...customerData, load_id: currentLoad.trip_id }))
      .then(res => {
        console.log("res", res);
        setSpecifications(res.json.view_specifications)
      })
      .catch(err => console.log("err", err))
  }, [currentLoad])
  const reject = (vehicleRequest) => {
    console.log("vehicleRequest", vehicleRequest);
    postWithAuthCallWithErrorResponse(ApiConfig.DIRECT_ORDER_OFFER_GOODS_VEHICLE_REJECT, JSON.stringify({ ...customerData }))
      .then(res => {
        if (res.json.result) {
          swal(res.json.message)
        }
      })
  }
  return (
    <main className={"vehicle-request-main " + mainClass} id="cxMain">
      {loading ? <Loader /> :
        <section className="table-vehicle-request" style={{ overflow: "auto" }}>
          <table className="datalist" style={{ minWidth: "1400px" }}>
            <thead>
              <tr>
                <td>
                  <label htmlFor="">S.No.</label>
                </td>
                <td>
                  <label htmlFor="">Offer Load Reference Number</label>
                </td>
                <td>
                  <label htmlFor="">Vehicle Availability Dt.</label>
                </td>
                <td>
                  <label htmlFor="">From & To</label>
                </td>
                <td>
                  <label htmlFor="">Cargo Type</label>
                </td>
                <td>
                  <label htmlFor="">Quantity</label>
                </td>
                <td>
                  <label htmlFor="">Vehicles Req.</label>
                </td>
              </tr>
            </thead>
            <tbody>
              {vehicleRequestList && vehicleRequestList.length && vehicleRequestList.map((vehicleRequest) => {
                console.log(
                  vehicleRequest
                );
                return (
                  <tr className="t-row ">
                    <td>
                      <p className="data m-0 ps-2">1</p>
                    </td>
                    <td>
                      <Link to="/GoodsDetails" state={vehicleRequest} className="ms-2 ViewSpecifications-link Vehicl-list-driverName-link">
                        {vehicleRequest.load_reference_no}
                      </Link>
                    </td>
                    <td>
                      <p className="data m-0">{vehicleRequest.trip_end_date}</p>
                    </td>
                    <td>
                      <p className="data m-0">{vehicleRequest.trip_start_city + " " + vehicleRequest.trip_start_country + "-" + vehicleRequest.trip_end_city + " " + vehicleRequest.trip_end_country}</p>
                    </td>
                    <td>
                      <p className="data m-0">{vehicleRequest.cargo_type}</p>
                    </td>
                    <td>
                      <p className="data m-0">{vehicleRequest.quantity}</p>
                    </td>
                    <td>
                      <Link
                        onClick={() => { setCurrentLoad(vehicleRequest); handleShow() }}
                        to="#"
                        className="ms-2 ViewSpecifications-link"
                        o
                      >
                        View Details
                      </Link>
                    </td>
                    <td><button className="vehicleRequest-btn-remove" onClick={() => reject(vehicleRequest)}>
                      <img src={remove} alt="" />
                    </button></td>
                    <td>

                      <button
                        className="vehicleRequest-btn ms-2"
                        onClick={() => { setCurrentLoad(vehicleRequest); handleShowAccept() }}
                      >
                        Accept
                      </button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </section>
      }

      <Modal
        show={show}
        onHide={handleClose}
        animation={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-content-box popup-specification"
      >
        <Modal.Header closeButton></Modal.Header>
        <div>
          <h3 className=" card-heading mt-2">Truck Specifications </h3>
        </div>
        <div
          className="btn-popup-modal text-center"
          style={{ overflow: "auto" }}
        >
          <table className="example table-striped" style={{ minWidth: "400px" }}>
            <thead>
              <tr>
                <td>
                  <label htmlFor="">Trucks</label>
                </td>
                <td>
                  <label htmlFor="">Vehicle Type</label>
                </td>
                <td>
                  <label htmlFor="">Capacity in Quintals</label>
                </td>
              </tr>
            </thead>
            <tbody>
              {specifications && specifications.length && specifications.map((spec, index) => {
                return (
                  <tr className="t-row ">
                    <td>
                      <p className="data m-0 ps-2">{index + 1}</p>
                    </td>
                    <td>
                      <p className="data m-0">{spec.vehicle_type}</p>
                    </td>
                    <td>
                      <p className="data m-0">{spec.capacity}</p>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </Modal>
      <Modal
        className="modal-md "
        id="myModal"
        role="dialog"
        onHide={handleCloseAccept}
        animation={true}
        show={showAccept}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>

        <div>
          <p className="text-center popup-heading mt-4">
            Are you sure you want to approve this request?
          </p>
        </div>
        <div className="btn-popup-modal justify-content-evenly mt-4 d-flex">
          <Link to="#" className="">
            <button type="button" className="popup-reject-btn ">
              Reject
            </button>
          </Link>

          <Link to="/OfferVehicles" state={currentLoad} className=""  >
            <button type="button" className="popup-approve-btn ">
              Approve
            </button>
          </Link>
        </div>
      </Modal>



    </main>
  );
};

export default OfferGoodsDirect;
