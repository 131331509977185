import React, { useContext, useEffect, useState } from "react";
import ApiConfig from "../api/ApiConfig";
import { PostCallWithErrorResponse } from "../api/ApiServices";
// import "../assets/css/login.scss";
import logo from "../assets/images/Login/logo.svg";
import swal from "sweetalert";
import LoadingBar from "react-top-loading-bar";
import { AppContext } from "../context/user/AppContext";
import { Link } from "react-router-dom";
const Login = ({ setLoggedIn }) => {
  const [state, setState] = useState({
    device_token: "",
    device_id: "",
    app_version: "",
    app_type: "transporter",
    device_os: "web",
    username: "",
    password: "",
  });
  const [progress, setProgress] = useState(0);
  const { setCustomerData, setUserDetails } = useContext(AppContext);
  const handleSubmit = (e) => {
    setProgress(25);
    setProgress(35);
    setProgress(45);
    setProgress(55);
    e.preventDefault();
    PostCallWithErrorResponse(ApiConfig.TRANSPORTER_LOGIN_API, state)
      .then((data) => {
        if (data.json.result) {
          setProgress(65);
          setProgress(75);
          setProgress(85);
          setProgress(100);
          setCustomerData({
            customer_id: data.json.customer_id,
            user_id: data.json.user_id,
            api_key: data.json.api_key,
          });
          localStorage.setItem("api_key", data.json.api_key);
          localStorage.setItem("customer_id", data.json.customer_id);
          localStorage.setItem("user_id", data.json.user_id);
          localStorage.setItem("logedIn", true);
          localStorage.setItem(
            "userDetails",
            JSON.stringify(data.json.user_details)
          );
          setUserDetails(data.json.user_details);
          setLoggedIn(true);
        } else {
          setProgress(100);
          swal({
            title: "error",
            text: data.json.message,
            icon: "error",
            button: true,
          });
        }
        console.log(data.json);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  return (
    <section className=" container-fluid login-main-section ">
      <LoadingBar
        color="red"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <div className="row">
        <div className="col-lg-6 col-md-6 left-section">
          <div className="left-content">
            <img src={logo} alt="" />
            <h3 className="mt-3 heading">Abay Logistics</h3>
            <p className="left-login-paragraph">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat.
            </p>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 right-section">
          <div className="right-content ">
            <h3>Login</h3>

            <p className="welcome-login">
              Welcome to <span>Abay Logistics</span>
            </p>
            <form action="" onSubmit={(e) => handleSubmit(e)}>
              <div className="mb-3 row">
                <label
                  htmlFor="inputEmail"
                  className="col-sm-12 col-form-label"
                >
                  Email
                </label>
                <div className="col-sm-12">
                  <input
                    value={state.username}
                    onChange={(e) =>
                      setState({ ...state, username: e.target.value })
                    }
                    type="email"
                    className="form-control"
                    id="inputEmail"
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label
                  htmlFor="inputPassword"
                  className="col-sm-12 col-form-label"
                >
                  Password
                </label>
                <div className="col-sm-12">
                  <input
                    type="password"
                    value={state.password}
                    onChange={(e) =>
                      setState({ ...state, password: e.target.value })
                    }
                    className="form-control"
                    id="inputPassword"
                  />
                </div>
              </div>
              <div className="forgot">
                <Link to="/ForgetPassword" style={{ textDecoration: "none" }}>
                  Forgot Password?
                </Link>
              </div>

              <div className="login-btn">
                <button
                  type="submit"
                  style={{ background: "#4f9fe6" }}
                  className="btn  col-sm-12 col-md-12 col-lg-12"
                >
                  <span style={{ color: "white" }}>Login</span>
                </button>
              </div>
              <div
                className="forgot pt-3"
                style={{
                  textAlign: "center",
                }}
              >
                <Link to="/AddTransporter">
                  Don't have an account?{" "}
                  <span
                    style={{
                      color: "#4C96CB",
                    }}
                  >
                    Register
                  </span>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
