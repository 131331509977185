import React, { useState, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../../assets/css/transistauction.scss";
import { AppContext } from "../../context/user/AppContext";
import swal from "sweetalert";

const TransporterAuction = () => {
  const { mainClass } = useContext(AppContext);

  const data = useLocation().state;
  const [loadDetail, setLoadDetail] = useState({ ...data })
  console.log("loadDetail", loadDetail);
  const [bidAmt, setBidAmt] = useState(0);
  const navigate = useNavigate();
  return (
    <>
      {/* <Sidebar/>
         <AbhayHeader/> */}
      <div className={"main " + mainClass} id="cxMain">
        <div className="details-main ">
          <div className="Auctioncards ">
            <div className="details-card ">
              <div className="row justify-content-center p-2">
                {/* left card */}
                <div className="col-lg-5  mt-2">
                  <div className="card card-style left-card ">
                    <div className="container-fluid ">
                      <div className="row pt-2">
                        <div className=" d-flex justify-content-between ">
                          <h4 className="card-gods ">Goods Details</h4>
                          <Link to="/GoodsDetails"
                            state={loadDetail} id="All">View All</Link>
                        </div>
                        <div
                          className=" justify-content-between align-items-start mb-2 "
                          id="short"
                        >
                          <div className="p-1  d-flex justify-content-between  rsponsive-card-data">
                            <h5 className="godside">Reference Number</h5>
                            <span className="allside">
                              {loadDetail && loadDetail.load_reference_no}
                            </span>
                          </div>
                        </div>
                        <div
                          className=" justify-content-between align-items-start  "
                          id="days"
                        >
                          <div className="p-1  d-flex align-items-middle justify-content-between  rsponsive-card-data">
                            <h5 className="godside">Company Name</h5>
                            <span className="allside">
                              {loadDetail && loadDetail.trip_company_name}
                            </span>
                          </div>
                        </div>

                        <div
                          className=" justify-content-between align-items-start mb-2 "
                          id="short"
                        >
                          <div className="p-1  d-flex align-items-middle justify-content-between rsponsive-card-data ">
                            <h5 className="godside">Cargo Type</h5>
                            <span className="allside">
                              {loadDetail && loadDetail.cargo_type}
                            </span>
                          </div>
                        </div>

                        <div
                          className=" justify-content-between align-items-start  "
                          id="days"
                        >
                          <div className=" p-1  d-flex align-items-middle justify-content-between  rsponsive-card-data">
                            <h5 className="godside">Quantity</h5>
                            <span className="allside">
                              {loadDetail && loadDetail.quantity} Quintals
                            </span>
                          </div>
                        </div>
                        <div
                          className=" justify-content-between align-items-start mb-2 "
                          id="short"
                        >
                          <div className="p-1 d-flex align-items-middle justify-content-between  rsponsive-card-data">
                            <h5 className="godside">Expected Arrival Time</h5>
                            <span className="allside">
                              {loadDetail && loadDetail.trip_end_date}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* RIGHT CARD  */}
                <div className="col-lg-5  mt-2">
                  <div className="card card-style left-card ">
                    <div className="m-2">
                      <div className="row">
                        <div className=" ps-4  card_title d-flex justify-content-between align-items-start ">
                          <h4 className="card-gods ">Auction Details</h4>
                        </div>

                        <div className="user_block ">
                          <div
                            className=" justify-content-between align-items-start  "
                            id="short"
                          >
                            <div className="  d-flex align-items-middle justify-content-between  p-1 rsponsive-card-data">
                              <h5 className="godside">Auction Name </h5>
                              <span className="allside">
                                {loadDetail &&
                                  loadDetail.auction_details &&
                                  loadDetail.auction_details.auction_name}{" "}
                              </span>
                            </div>
                          </div>
                          <div
                            className=" justify-content-between align-items-start  "
                            id="days"
                          >
                            <div className="  d-flex align-items-middle justify-content-between  p-1 rsponsive-card-data">
                              <h5 className="godside">Auction Duration</h5>
                              <span className="allside">
                                {" "}
                                {loadDetail &&
                                  loadDetail.auction_details &&
                                  loadDetail.auction_details.duration}
                              </span>
                            </div>
                          </div>

                          <div
                            className=" justify-content-between align-items-start  "
                            id="short"
                          >
                            <div className="p-1  d-flex align-items-middle justify-content-between  rsponsive-card-data">
                              <h5 className="godside">Auction Type</h5>
                              <span className="allside">
                                {loadDetail &&
                                  loadDetail.auction_details &&
                                  loadDetail.auction_details.auction_type}
                              </span>
                            </div>
                          </div>

                          <div
                            className=" justify-content-between align-items-start  "
                            id="days"
                          >
                            <div className="  d-flex align-items-middle justify-content-between  p-1 rsponsive-card-data">
                              <h5 className="godside">Your Bid</h5>
                              <span className="allside">None</span>
                            </div>
                          </div>
                          <div
                            className=" justify-content-between align-items-start  "
                            id="short"
                          >
                            <div className="p-1 d-flex align-items-middle justify-content-between  rsponsive-card-data">
                              <h5 className="godside">Start Date</h5>
                              <span className="allside">
                                {loadDetail &&
                                  loadDetail.auction_details &&
                                  loadDetail.auction_details.auction_start_date}
                              </span>
                            </div>
                          </div>
                          <div
                            className=" justify-content-between align-items-start  "
                            id="days"
                          >
                            <div className="  d-flex align-items-middle justify-content-between  p-1 rsponsive-card-data">
                              <h5 className="godside">Expiry Date</h5>
                              <span className="allside">
                                {loadDetail &&
                                  loadDetail.auction_details &&
                                  loadDetail.auction_details.auction_end_date}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="bottom">
                            <div className="text-center mb-2">
                              <label className="text-center">
                                {" "}
                                Enter Bid Amount
                              </label>
                            </div>
                            <div className="ms-2 me-2 mb-2 justify-content-center ">
                              <input
                                className="form-control"
                                type="number"
                                onChange={(e) => setBidAmt(e.target.value)}
                                // min="1"
                                // max="1000"
                                placeholder="450"
                              ></input>
                            </div>
                          </div>

                          <div className="row  make_bid m-1">
                            <button
                              onClick={() => {
                                if (bidAmt > 0) {
                                  navigate("/OfferVehicles", {
                                    state: { ...loadDetail, amount: bidAmt },
                                  });
                                } else {
                                  swal("Enter bid amount");
                                }
                              }}
                              type="button"
                              // onClick={handleShow}
                              data-bs-dismiss="offcanvas"
                              className="btn-lg "
                              id="save"
                            >
                              Make a Bid
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransporterAuction;
