import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ic_options from "../assets/images/Trasnvehicle/ic_options.svg";
import dropdown_arrow_blue from "../assets/images/dropdown_arrow_blue.svg";
import { Modal } from "react-bootstrap";
import ic_vehicle from "../assets/images/AddVehicles/ic_vehicle.svg";
import circle_cross from "../assets/images/circle_cross.svg";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { AppContext } from "../context/user/AppContext";
import {
  PostCallWithErrorResponse,
  postMultipartWithAuthCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
} from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import swal from "sweetalert";

const OfferVehicles = () => {
  const { mainClass, customerData } = useContext(AppContext);
  const navigate = useNavigate();
  const data = useLocation().state;
  const [Load, setLoad] = useState({ ...data });

  console.log("Load", Load);
  console.log("customerData", customerData);
  $(document).ready(function () {
    $(".datalist").DataTable();
  });
  const [selectVehicle, setSelectedVehicle] = useState({ vehicle_images: [] });
  const [show, setShow] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);
  const [vehicleList, setVehicleList] = useState([]);
  const [addedVehicleList, setAddedVehicleList] = useState([]);
  useEffect(() => {
    postMultipartWithAuthCallWithErrorResponse(
      ApiConfig.VEHICLE_LIST,
      JSON.stringify({ ...customerData })
    )
      .then((res) => {
        console.log("res...", res);
        setVehicleList(res.json.vehicle_list);
      })
      .catch((err) => console.log(err));
  }, []);
  const setCurrentVehicle = (e) => {
    let vehicle = vehicleList.filter(
      (vehicle) => vehicle.plate_number === e.target.value
    )[0];
    console.log("vehicle", vehicle);
    setSelectedVehicle({
      ...vehicle,
      load_reference_no: Load && Load.load_reference_no,
    });
  };

  const sendOffer = (e) => {
    e.preventDefault();
    if (addedVehicleList.length) {
      let vehicleList = addedVehicleList.map((vehicle) => {
        return {
          vehicle_type: vehicle.vehicle_type,
          plate_no: vehicle.plate_number,
        };
      });
      console.log(vehicleList);
      if (Load.amount) {
        postWithAuthCallWithErrorResponse(
          ApiConfig.ONLINE_AUCTION_OFFERGOODS_MAKE_BID,
          JSON.stringify({
            ...customerData,
            load_id: Load && Load.trip_id,
            vehicle_details: vehicleList,
            amount: Load && Load.amount,
          })
        ).then((res) => {
          console.log("res", res);
          if (res.json.result) {
            swal(res.json.message);
          }
        });
      } else {
        postWithAuthCallWithErrorResponse(
          ApiConfig.DRIRECT_ORDER_OFFER_GOODS_VEHICLE_ACCEPT,
          JSON.stringify({
            ...customerData,
            load_id: Load && Load.trip_id,
            vehicle_details: vehicleList,
          })
        ).then((res) => {
          console.log("res", res);
          if (res.json.result) {
            swal(res.json.message);
            navigate("/VehicleRequestOnlineAuction");
          }
        });
      }
    } else {
      swal("add some vehicles to send offer");
    }
  };
  return (
    <>
      <main className={"main-offerVehicles " + mainClass}>
        <div className="btn-offer-vehicle-main text-end mb-4">
          <button className="btn-offer-vehicle " onClick={() => setShow(true)}>
            Offer Vehicles
          </button>
        </div>

        <div
          className=""
          style={{
            width: "100",
            overflow: "auto",
            height: "50vh",
            marginBottom: "60px",
          }}
        >
          <table className="  ">
            <thead>
              <tr>
                <th>
                  <label>S.No</label>
                </th>
                <th>
                  <label>Reference Number</label>
                </th>
                <th>
                  <label>Owner Name</label>
                </th>
                <th>
                  <label>Vehicle Type</label>
                </th>
                <th>
                  <label>Model</label>
                </th>
                <th>
                  <label>Plate No.</label>
                </th>
                <th>
                  <label>Driver Name</label>
                </th>
                <th>
                  <label></label>
                </th>
              </tr>
            </thead>
            <tbody>
              {addedVehicleList.map((vehicle, index) => {
                return (
                  <tr className="Rowbgcolor " key={index + "vehicle"}>
                    <td>{index + 1}</td>
                    <td id="Owner">{vehicle.load_reference_no} </td>
                    <td id="Vehicle">{vehicle.owner_name}</td>
                    <td id="Model">{vehicle.vehicle_type}</td>
                    <td id="Plate">{vehicle.vehicle_model_no}</td>
                    <td id="DriverNAM">{vehicle.plate_number}</td>
                    <td id="Nationality">{vehicle.driver_name}</td>
                    <td>
                      <div className="option-dropdown dropdown p-2">
                        <a
                          href=""
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img src={ic_options} alt="" />
                        </a>
                        <ul className="dropdown-menu ">
                          <li>
                            <button className="dropdown-item" type="button">
                              Edit{" "}
                              <img
                                src={dropdown_arrow_blue}
                                className="arrowvehicle"
                                alt=""
                              />
                            </button>
                          </li>
                          <li>
                            <Link
                              to="/ViewDetails"
                              className="text-decoration-none"
                            >
                              <button className="dropdown-item" type="button">
                                View Details
                              </button>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/ChangeDriver"
                              className="text-decoration-none"
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasDriverAccount"
                              aria-controls="offcanvasRightAccount"
                            >
                              <button className="dropdown-item " type="button">
                                Change Driver
                              </button>
                            </Link>
                          </li>
                          <li>
                            <button className="dropdown-item" type="button">
                              Block
                            </button>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="fix-offer-vehicles" style={{ overflow: "auto" }}>
          <div className="row p-4  mb-4" style={{ minWidth: "1250px" }}>
            <div className="col-2 ">
              <label htmlFor="">Offer Load Reference No.</label>
              <p className="data">{Load && Load.load_reference_no}</p>
            </div>
            <div className="col-2 ">
              <label htmlFor="">Availability date of vehicle</label>
              <p className="data">{Load && Load.vehicle_availability_date}</p>
            </div>
            <div className="col-2 ">
              <label htmlFor="">Cargo Type</label>
              <p className="data">{Load && Load.cargo_type}</p>
            </div>
            <div className="col-2 ">
              <label htmlFor="">Quantity</label>
              <p className="data">{Load && Load.quantity}</p>
            </div>
            <div className="col-2 ">
              <label htmlFor="">Number of Trucks</label>
              <p>
                <span className="data m-0">{Load && Load.truck_count}</span>{" "}
              </p>
            </div>
          </div>
          <div className="fix-bottom-bar d-flex justify-content-between align-items-center mt-4">
            <div className="left"></div>
            <div className="right">
              <a href="#">
                <button type="cancel" className="cancel-btn">
                  Cancel
                </button>
              </a>
              <Link to="#" onClick={(e) => sendOffer(e)}>
                <button type="submit" className="continue-btn">
                  Send Offer
                </button>
              </Link>
            </div>
          </div>
        </div>
      </main>

      <Modal
        show={show}
        fullscreen={fullscreen}
        onHide={() => setShow(false)}
        className="offer-vehicle-popup"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="card-heading">Offer Vehicle</h5>
          </Modal.Title>
        </Modal.Header>
        <hr />

        <Modal.Body>
          <section className="offer-vehicles ">
            <div className="row " style={{ marginBottom: "70px" }}>
              <div className="col-lg-3 col-md-6 ">
                <div className="top-item mt-2">
                  <label className="" style={{ color: "#66739B" }}>
                    Upload Vehicle Images
                  </label>
                  <div className="border-block ">
                    <div className="uploading">
                      <img src={ic_vehicle} alt="" />
                      <p className="m-0 mt-3 drag-drop">
                        Drag & Drop files to upload
                      </p>
                      <p className="drag-drop">OR</p>
                      <a href="#" style={{ color: "#4D9DE7" }}>
                        Browse Files
                      </a>
                    </div>
                  </div>
                </div>
                <div className="uploaded_items d-flex mt-4">
                  {selectVehicle.vehicle_images &&
                    selectVehicle.vehicle_images.map((img) => {
                      return (
                        <div className="item_list">
                          <img
                            className="vehicle_img"
                            width="100"
                            height={100}
                            src={
                              ApiConfig.BASE_URL_FOR_IMAGES +
                              img.vehicle_image_url
                            }
                            alt="No image "
                          />
                          <a href="#" className="close_item">
                            <img src={circle_cross} alt="" />
                          </a>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="col-lg-9 col-md-6">
                <div className="row">
                  <div className="col-lg-4 ">
                    <div className="form-group mt-2">
                      <label htmlFor="exampleInputEmail1">
                        Reference Number
                      </label>
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={Load && Load.load_reference_no}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="form-group mt-2">
                      <label htmlFor="exampleInputEmail1">Plate Number</label>
                      <select
                        type="search"
                        className="form-select"
                        id="exampleInputEmail1"
                        onChange={(e) => {
                          setCurrentVehicle(e);
                        }}
                        aria-describedby="emailHelp"
                      >
                        <option>Select Vehicle</option>
                        {vehicleList &&
                          vehicleList.length &&
                          vehicleList.map((vehicle) => {
                            return (
                              <option value={vehicle.plate_number}>
                                {" "}
                                {vehicle.plate_number}{" "}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="form-group mt-2">
                      <label htmlFor="exampleInputEmail1">Model</label>
                      <input
                        type="text"
                        disabled
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={selectVehicle.vehicle_model_no}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Vehicle Type</label>
                      <input
                        disabled
                        className="form-control"
                        aria-label="Default select example"
                        value={selectVehicle.vehicle_type}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">
                        Year of Manufacture
                      </label>
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={selectVehicle.year_manufacture}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Chassis Number</label>
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={selectVehicle.vehicle_chassis_no}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Motor Number</label>
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={selectVehicle.vehicle_motor_no}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Gross Weight</label>
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={selectVehicle.vehicle_gross_weight}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">
                        Vehicle Load Capacity in Quintal
                      </label>
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={selectVehicle.vehicle_capacity}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Current km</label>
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={selectVehicle.vehicle_capacity}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Driver Name</label>
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={
                          selectVehicle.driver_details &&
                          selectVehicle.driver_details.user_name
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">
                        Driver Driving License
                      </label>
                      <input
                        disabled
                        type="search"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={
                          selectVehicle.driver_details &&
                          selectVehicle.driver_details.licence_number
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="fix-bottom-bar d-flex justify-content-between align-items-center mt-4">
            <div className="left"></div>
            <div className="right">
              <a href="#">
                <button
                  type="submit"
                  className="cancel-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    setShow(false);
                  }}
                >
                  Cancel
                </button>
              </a>
              <Link to="#">
                <button
                  type="submit"
                  className="continue-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    setShow(false);
                    setVehicleList(
                      vehicleList.filter(
                        (vehicle) =>
                          vehicle.plate_number !== selectVehicle.plate_number
                      )
                    );
                    let AddedVehicleList = [...addedVehicleList, selectVehicle];
                    setAddedVehicleList(AddedVehicleList);
                  }}
                >
                  Add Vehicle
                </button>
              </Link>
              {/* <div className="login-btn">
                <button
                  type="submit"
                  
                  className="btn  col-sm-12 col-md-12 col-lg-12"
                >
                  <span style={{ color: "white" }}> Continue</span>
                </button>
              </div> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OfferVehicles;
