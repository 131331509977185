import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ApiConfig from "../api/ApiConfig";
import { postWithAuthCallWithErrorResponse } from "../api/ApiServices";
import { AppContext } from "../context/user/AppContext";
import Loader from "../sharedComponent/Loader";
const ViewOrder = () => {
  const data = useLocation().state;
  const [order, setorder] = useState({ ...data })
  const { mainClass, customerData } = useContext(AppContext);
  const [loading, setLoading] = useState(false)
  console.log("order", order);
  useEffect(() => {
    setLoading(true);
    postWithAuthCallWithErrorResponse(ApiConfig.ORDER_CONFIRMATION_ORDER_DETAILS, JSON.stringify({ load_id: order.trip_id, ...customerData }))
      .then(res => {
        setLoading(false);
        console.log(res);
        if (res.json.result) {
          setorder(res.json.load_details)
        }
      }).catch(err => {
        console.log(err);
      })
  }, [])
  return (
    <main className={"View-Order-main " + mainClass} id="cxMain">
      {loading ? <Loader loading={loading} /> :
        <section className="viewOrder-body common-body mt-4">
          <div className="row">
            <div className="col-lg-3 col-sm-6 ">
              <label htmlFor="">Reference Number (Offer Vehicle)</label>
              <p className="data">{order && order.trip_reference_no}</p>
            </div>
            <div className="col-lg-3 col-sm-6 ">
              <label htmlFor="">Operation Number</label>
              <p className="data">{order && order.trip_operation_no}</p>
            </div>
            <div className="col-lg-3 col-sm-6 ">
              <label htmlFor="">Transporter</label>
              <p className="data">{order && order.transporter}</p>
            </div>
            <div className="col-lg-3 col-sm-6 ">
              <label htmlFor="">Plate No.</label>
              <p className="data">{order && order.vehicles && order.vehicles.map(vehicle => vehicle.plate_no).join(",")}</p>
            </div>
            <div className="col-lg-3 col-sm-6 ">
              <label htmlFor="">Company Name (Shipper)</label>
              <p className="data">{order && order.shipper}</p>
            </div>
            <div className="col-lg-3 col-sm-6 ">
              <label htmlFor="">Cargo Type</label>
              <p className="data">{order && order.cargo_type}</p>
            </div>
            <div className="col-lg-3 col-sm-6 ">
              <label htmlFor="">Quantity</label>
              <p className="data">{order && order.quantity}</p>
            </div>
            <div className="col-lg-3 col-sm-6 ">
              <label htmlFor="">Unit Price</label>
              <p className="data">${order && order.unit_price}</p>
            </div>
            <div className="col-lg-3 col-sm-6 ">
              <label htmlFor="">Total Price</label>
              <p className="data">{order && order.total_price}</p>
            </div>
            <div className="col-lg-3 col-sm-6 ">
              <label htmlFor="">Loading Place</label>
              <p className="data">{order && order.loading_place}</p>
            </div>
            <div className="col-lg-3 col-sm-6 ">
              <label htmlFor="">Expected Loading Date</label>
              <p className="data">{order && order.loading_date}</p>
            </div>
            <div className="col-lg-3 col-sm-6 ">
              <label htmlFor="">Exp. Arrival Time At The Destination</label>
              <p className="data">{order && order.arrival_time}</p>
            </div>
            <div className="col-lg-3 col-sm-6 ">
              <label htmlFor="">Driver Name</label>
              <p className="data">{order && order.vehicles && order.vehicles.map(vehicle => vehicle.driver_name).join(",")}</p>
            </div>
            <div className="col-lg-3 col-sm-6 ">
              <label htmlFor="">Driver Cell Phone</label>
              <p className="data">{order && order.vehicles && order.vehicles.map(vehicle => vehicle.driver_phone_no).join(",")}</p>
            </div>

          </div>
        </section>
      }

    </main>
  );
};

export default ViewOrder;
