import { City } from "country-state-city";
import { useEffect, useState } from "react";
import Select from "react-select";
export default function CitySelect({ setCity, country }) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(
      City.getCitiesOfCountry(country).map((city) => {
        return { value: city.name, label: city.name };
      })
    );
  }, [country]);
  return (
    <div className="form-group mt-4">
      <label htmlFor="exampleInputEmail1">City</label>
      <Select options={options} onChange={setCity} />
    </div>
  );
}
