import React, { useContext, useEffect, useState } from "react";
// import "../../assets/css/VehiclesDetails.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../../context/user/AppContext";
import { PostCallWithErrorResponse, postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../../sharedComponent/Loader";
import ProgressBar from "../../sharedComponent/ProgressBar";
import swal from "sweetalert";
const VehiclesDetails = () => {
  const customerData = useContext(AppContext).customerData
  const [progress, setProgrss] = useState(0)
  const vehicle = useLocation().state;
  const [loading, setLoading] = useState(false)
  const [vehicleDetails, setVehicleDetails] = useState({})
  const { mainClass } = useContext(AppContext);
  const navigate = useNavigate()
  useEffect(() => {
    setLoading(true)

    PostCallWithErrorResponse(ApiConfig.VEHICLE_DETAILS, { vehicle_id: vehicle.vehicle_id, ...customerData })
      .then(res => {
        setLoading(false)
        if (!res.error) {
          setVehicleDetails(res.json.vehicle_details)
        }
      })
      .catch(err => console.log(err))
  }, [])
  const blockVehicle = () => {
    setProgrss(25);
    setProgrss(50);
    PostCallWithErrorResponse(ApiConfig.BLOCK_VEHICLE, JSON.stringify({ ...customerData, vehicle_id: vehicle.vehicle_id }))
      .then(res => {
        setProgrss(100);
        if (res.json.result) {
          swal(res.json.message)
          navigate("/VehicleList");
        }
      }).catch(err => console.log(err))
  }
  return (
    <>
      <main className={"main-section-VehicleDetails " + mainClass} id="cxMain">
        <ProgressBar progress={progress} />
        {loading ? <Loader /> :

          <div className="main-body-VehicleDetails mb-4">
            {/* vehicles details */}
            <section className="vehicle-detail-section mb-0 common-body p-3">
              <div className="row">
                <div className="col-lg-6">
                  <div className="image">
                    <div
                      id="carouselExampleControls"
                      className="carousel slide"
                      data-bs-ride="carousel"
                    >
                      <div className="carousel-inner">
                        {vehicleDetails.vehicle_images && vehicleDetails.vehicle_images.length && vehicleDetails.vehicle_images.map((img, index) => {
                          return (
                            index === 0 ?
                              <div className="carousel-item active" key={index + "vehicleImage"}>
                                <img
                                  src={ApiConfig.BASE_URL_FOR_IMAGES + img.vehicle_image_url}
                                  className="d-block "
                                  alt={"..."}
                                />
                              </div>
                              :
                              <div className="carousel-item" key={index + "vehicleImage"}>
                                <img
                                  src={ApiConfig.BASE_URL_FOR_IMAGES + img.vehicle_image_url}
                                  className="d-block "
                                  alt={"..."}
                                />
                              </div>
                          )

                        })}


                      </div>
                      <button
                        className="carousel-control-prev"
                        type="button"
                        data-bs-target="#carouselExampleControls"
                        data-bs-slide="prev"
                      >
                        <span
                          className="carousel-control-prev-icon"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Previous</span>
                      </button>
                      <button
                        className="carousel-control-next"
                        type="button"
                        data-bs-target="#carouselExampleControls"
                        data-bs-slide="next"
                      >
                        <span
                          className="carousel-control-next-icon"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Next</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="right-section">
                    <div className="right-top-content d-flex justify-content-between">
                      <div className="title-vehicle">
                        <h4 className="name-vehicle">{vehicleDetails && vehicleDetails.vehicle_name}</h4>
                      </div>
                      {/* <div className="icon-dropdown dropdown">
                        <a
                          href=""
                          className=""
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img src={ic_options_menu} alt="" height="24" />
                        </a>

                        <ul className="dropdown-menu">
                          <li>
                            <button className="dropdown-item " type="button">
                              Edit{" "}
                              <img
                                src={dropdown_arrow_blue}
                                className="arrow"
                                alt=""
                              />
                            </button>
                          </li>
                          <li>
                            <Link

                              to="/ChangeDriver"
                              className="text-decoration-none"
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasDriverAccount"
                              aria-controls="offcanvasRightAccount"
                            >
                              <button className="dropdown-item " type="button">
                                Change Driver
                              </button>
                            </Link>
                          </li>
                          <li>
                            <button className="dropdown-item" type="button" onClick={blockVehicle}>
                              Block
                            </button>
                          </li>
                        </ul>
                      </div> */}
                      {/* <div className="rating">
                     <img src={star_highlighted} alt="" />
                     <img src={star_highlighted} alt="" />

                     <img src={star_highlighted} alt="" />
                     <img src={star_highlighted} alt="" />
                     <img src={star_grey} alt="" />
                     <span>(320)</span>
                   </div> */}
                    </div>
                    <div className="bottom-content row mt-4">
                      <div className="col-lg-4 left item">
                        <label>Plate Number</label>
                        <p className="data">{vehicleDetails && vehicleDetails.plate_number}</p>
                      </div>
                      <div className="col-lg-4 middel item">
                        <label>Vehicle Type</label>
                        <p className="data">{vehicleDetails && vehicleDetails.vehicle_name}</p>
                      </div>
                      <div className=" col-lg-4 right item">
                        <label>Year of Manufacture</label>
                        <p className="data">{vehicleDetails && vehicleDetails.year_manufacture}</p>
                      </div>
                    </div>
                    <div className="bottom-content row mt-3">
                      <div className="col-lg-4 left item">
                        <label>Chassis Number</label>
                        <p className="data">{vehicleDetails && vehicleDetails.vehicle_chassis_no}</p>
                      </div>
                      <div className="col-lg-4 middel item">
                        <label>Motor Number</label>
                        <p className="data">{vehicleDetails && vehicleDetails.vehicle_motor_no}</p>
                      </div>
                      <div className="col-lg-4 right item">
                        <label>Gross Weight</label>
                        <p className="data">{vehicleDetails && vehicleDetails.vehicle_gross_weight}</p>
                      </div>
                    </div>
                    <div className="bottom-content row mt-3">
                      <div className="col-lg-4 left item">
                        <label>Vehicle Load Capacity</label>
                        <p className="data">{vehicleDetails && vehicleDetails.vehicle_capacity} Quintals</p>
                      </div>

                      <div className="col-lg-4 right item">
                        <label>Initial km</label>
                        <p className="data">{vehicleDetails && vehicleDetails.vehicle_initial_km}</p>
                      </div>
                    </div>
                    <h5 className="card-heading">GPS Availability</h5>
                    <div className="bottom-content row mt-3">
                      <div className="col-lg-4 left item">
                        <label>Vendor Name</label>
                        <p className="data">{vehicleDetails && vehicleDetails.vehicle_vendor_name}</p>
                      </div>

                      <div className="col-lg-4 right item">
                        <label>Vendor Contact</label>
                        <p className="data">{vehicleDetails && vehicleDetails.vehicle_vendor_contact}</p>
                      </div>
                      <div className="col-lg-4 right item">
                        <label>Vendor Platform</label>
                        <p className="data">{vehicleDetails && vehicleDetails.vehicle_vendor_platform}</p>
                      </div>
                      <div className="col-lg-4 right item">
                        <label>Vendor Address</label>
                        <p className="data">{vehicleDetails && vehicleDetails.vehicle_vendor_address}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* vehicles documents details */}
            <section className="documents-detail-section common-body p-3 mt-4">
              <div className="row">
                <div className="col-12">
                  <div className="top-content">
                    <h5 className="card-heading">Documents</h5>
                    <hr />
                  </div>
                  <div className="row">
                    <div className="col-lg-2 col-md-6 ">
                      <div className="insurance-copy">
                        <label>Insurance Copy</label>
                        <div className="menu-option d-flex align-items-start">
                          <img src={ApiConfig.BASE_URL_FOR_IMAGES + "/" + vehicleDetails.vehicle_insurance_image} alt="" width="132" height="100" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-6 ">
                      <label>Issue Date</label>
                      <p className="data">{vehicleDetails && vehicleDetails.vehicle_insurance_issue_date}</p>
                    </div>
                    <div className="col-lg-2 col-md-6 ">
                      <label>Expiry Date</label>
                      <p className="data">{vehicleDetails && vehicleDetails.vehicle_insurance_expiry}</p>
                    </div>
                    <div className="col-lg-2 col-md-6 ">
                      <label>Insurance Company</label>
                      <p className="data">{vehicleDetails && vehicleDetails.vehicle_insurance_company}</p>
                    </div>
                    <div className="col-lg-2 col-md-6 ">
                      <label>Insurance Type</label>
                      <p className="data">{vehicleDetails && vehicleDetails.vehicle_insurance_type}</p>
                    </div>
                    <div className="col-lg-2 col-md-6 ">
                      <label>Sum Insuranced</label>
                      <p className="data">${vehicleDetails && vehicleDetails.vehicle_sum_insured}</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* vehicles owner details */}
            {/* <section className="owner-detail-section common-body p-3 mt-4 mb-4">
           <div className="row">
             <div className="col-12">
               <div className="top-content">
                 <h5 className="card-heading">Owner Details</h5>
                 <hr />
               </div>
               <div className="row">
                 <div className="col-lg-2 col-md-6 ">
                   <label>Full Name</label>
                   <p className="data">{vehicleDetails.owner_details && vehicleDetails.owner_details.user_name}</p>
                 </div>
                 <div className="col-lg-2 col-md-6 ">
                   <label>Email</label>
                   <p className="data">{vehicleDetails.owner_details && vehicleDetails.owner_details.user_email}</p>
                 </div>
                 
                 <div className="col-lg-2 col-md-6 ">
                   <label>Mobile Number</label>
                   <p className="data">{vehicleDetails.owner_details && vehicleDetails.owner_details.user_mobile}</p>
                 </div>
                 <div className="col-lg-2 col-md-6 ">
                   <label>City</label>
                   <p className="data">{vehicleDetails.owner_details && vehicleDetails.owner_details.user_city}</p>
                 </div>
                 <div className="col-lg-2 col-md-6  ">
                   <label>Country</label>
                   <p className="data">{vehicleDetails.owner_details && vehicleDetails.owner_details.user_country}</p>
                 </div>
                 <div className="col-lg-2 col-md-6 ">
                   <label>Birthdate</label>
                   <p className="data">{vehicleDetails.owner_details && vehicleDetails.owner_details.user_dob}</p>
                 </div>
                 <div className="col-lg-2 col-md-6  ">
                   <label>Gender</label>
                   <p className="data">{vehicleDetails.owner_details && vehicleDetails.owner_details.user_gender}</p>
                 </div>
                 <div className="col-lg-6 col-md-6  ">
                   <label>Address</label>
                   <p className="data">
                     {vehicleDetails.owner_details && vehicleDetails.owner_details.user_address}
                   </p>
                 </div>
               </div>
             </div>
           </div>
         </section> */}
          </div>
        }

      </main>
    </>
  );
};

export default VehiclesDetails;
