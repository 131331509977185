import "./assets/css/main.scss";
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  Link,
} from "react-router-dom";
import React from "react";
import Login from "./pages/Login";
import "./assets/css/style.scss";
import VehicleDriverDetailsHeader from "./pages/vehicles/VehicleDriverDetailsHeader";
import DriverDetails from "./pages/Driver/DriverDetails";
import VehiclesDetails from "./pages/vehicles/VehiclesDetails";
import GoodsDetails from "./sharedComponent/GoodsDetails";
import FAQ from "./pages/faq/FAQ";
import ContactUs from "./sharedComponent/ContactUs";
import TermsAndConditions from "./sharedComponent/TermsAndConditions";
import AbhayHeader from "./sharedComponent/AbhayHeader";
import Sidebar from "./sharedComponent/Sidebar";
import CompleteFreights from "./pages/fright/CompleteFreights";
import OngoingFreights from "./pages/fright/OngoingFreights";
import VehicleList from "./pages/vehicles/VehicleList";
import DriverList from "./pages/Driver/DriverList";
import Rating from "./pages/Transporter/Rating";
import Profile from "./pages/profile/Profile";
import GPS_Shipper from "./pages/liveTracking/GPS_Shipper";
import OngoingFreightsMenu from "./pages/fright/OngoingFreightsMenu";
import OrderConfirmation from "./sharedComponent/OrderConfirmation";
import FreightsHeaders from "./pages/fright/Freightsheader";
import Contracts from "./pages/contactUs/Contracts";
import ViewDetails from "./pages/fright/ViewDetails_Goods_Driver_Vehicle";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import OfferVehicles from "./sharedComponent/OfferVehicles";
import VehicleRequestOnlineAuction from "./pages/onlineAuction/VehicleRequestOnlineAuction";
import TransporterAuction from "./pages/onlineAuction/TransporterAuction";
import OfferGoodsDirect from "./pages/directOrder/OfferGoodsDirect";
import OfferedVehicles from "./pages/directOrder/OfferedVehicles";
import AddTransporter from "./sharedComponent/AddTransporter";
import AddTransporterSecond from "./sharedComponent/AddTransporterSecond";

import ViewOrder from "./sharedComponent/ViewOrder";
import { useEffect, useState } from "react";
import OfferedVehiclesViewDetails from "./pages/directOrder/OfferedVehiclesViewDetails";
import Dashboard from "./pages/dashboard/Dashboard";
import { Reports } from "./pages/fright/Reports";
import SelectReportTypes from "./pages/fright/SelectReportTypes";
import UpcomingFreights from "./pages/fright/UpcomingFreights";
import Notifications from "./pages/notification/Notifications";
import { Modal } from "react-bootstrap";
import TransporterRatesImport from "./pages/Rates/TransporterRatesImport";
import TransporterRatesExport from "./pages/Rates/TransporterRatesExport";
import ForgetPassword from "./sharedComponent/ForgetPassword";
import ResetPassword from "./sharedComponent/ResetPassword";

function App() {
  const currentRoute = useLocation().pathname;
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [logedIn, setLoggedIn] = useState(
    localStorage.getItem("logedIn") ? localStorage.getItem("logedIn") : false
  );
  // if (location === "/") {
  //   return <GetStarted />
  // }
  // if (!logedIn) {
  //   return <Login setLoggedIn={setLoggedIn} />
  // }
  const handleClose = () => setShow(false);
  useEffect(() => {
    if (!logedIn) {
      navigate("/");
    } else {
      setShow(true);
      navigate("Dashboard");
    }
  }, [logedIn]);
  useEffect(() => {
    navigate(currentRoute);
  }, [currentRoute]);
  return (
    <div className="App">
      {/* {currentRoute !== "/" && currentRoute !== "Login" && (
        <>
          <Sidebar />
          <AbhayHeader setLoggedIn={setLoggedIn} />
        </>
      )} */}
      {currentRoute !== "/" && currentRoute !== "Login" && (
        <>
          {!(
            window.location.pathname === "/AddTransporter" ||
            window.location.pathname === "/AddTransporterSecond" ||
            window.location.pathname === "/ForgetPassword" ||
            window.location.pathname === "/ResetPassword"
          ) ? (
            <>
              <Sidebar />
              <AbhayHeader setLoggedIn={setLoggedIn} />
            </>
          ) : (
            <></>
          )}
        </>
      )}
      <Routes>
        <Route
          path={"/" || "/Login"}
          element={<Login setLoggedIn={setLoggedIn} />}
        />
        <Route path="Dashboard" element={<Dashboard />} />
        <Route
          path="VehicleDriverDetailsHeader"
          element={<VehicleDriverDetailsHeader />}
        />
        <Route path="DriverDetails" element={<DriverDetails />} />
        <Route path="ForgetPassword" element={<ForgetPassword />} />
        <Route path="ResetPassword" element={<ResetPassword />} />
        <Route path="VehiclesDetails" element={<VehiclesDetails />} />
        <Route
          path="TransporterRatesImport"
          element={<TransporterRatesImport />}
        />
        <Route
          path="TransporterRatesExport"
          element={<TransporterRatesExport />}
        />
        <Route path="GoodsDetails" element={<GoodsDetails />} />
        <Route path="FAQ" element={<FAQ />} />
        <Route path="ContactUs" element={<ContactUs />} />
        <Route path="TermsAndConditions" element={<TermsAndConditions />} />
        <Route path="AddTransporter" element={<AddTransporter />} />
        <Route path="AddTransporterSecond" element={<AddTransporterSecond />} />
        <Route path="Notifications" element={<Notifications />} />
        <Route path="AbhayHeader" element={<AbhayHeader />} />
        <Route path="Sidebar" element={<Sidebar />} />
        <Route path="CompleteFreights" element={<CompleteFreights />} />
        <Route path="OngoingFreights" element={<OngoingFreights />} />
        <Route path="UpcomingFreights" element={<UpcomingFreights />} />
        <Route path="OngoingFreightsMenu" element={<OngoingFreightsMenu />} />
        <Route path="TransporterAuction" element={<TransporterAuction />} />
        <Route path="VehicleList" element={<VehicleList />} />
        <Route path="OrderConfirmation" element={<OrderConfirmation />} />
        <Route path="OrderConfirmationOnline" element={<OrderConfirmation />} />
        <Route path="DriverList" element={<DriverList />} />
        <Route path="Reports" element={<Reports />} />
        <Route path="SelectReportTypes" element={<SelectReportTypes />} />
        <Route path="Rating" element={<Rating />} />
        <Route path="Profile" element={<Profile />} />
        <Route path="GPS_Shipper" element={<GPS_Shipper />} />
        <Route path="FreightsHeaders" element={<FreightsHeaders />} />
        <Route path="Contracts" element={<Contracts />} />
        <Route path="ViewDetails" element={<ViewDetails />} />
        <Route path="OfferGoodsDirect" element={<OfferGoodsDirect />} />
        <Route path="OfferVehicles" element={<OfferVehicles />} />
        <Route
          path="VehicleRequestOnlineAuction"
          element={<VehicleRequestOnlineAuction />}
        />
        <Route path="OfferedVehicles" element={<OfferedVehicles />} />
        <Route path="OfferedVehiclesOnline" element={<OfferedVehicles />} />
        <Route path="ViewOrder" element={<ViewOrder />} />
        <Route
          path="OfferedVehiclesViewDetails/"
          element={<OfferedVehiclesViewDetails />}
        >
          <Route path=":id" element={<OfferedVehiclesViewDetails />} />
        </Route>
      </Routes>
      {/* <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-content-box popup-dashboard"
      >
        <Modal.Header closeButton></Modal.Header>
        <div>
          <p className="text-center popup-heading mt-4">
            Select where you want to go?
          </p>
        </div>
        <div className="btn-popup-modal text-center">
          <div className="">
            <Link
              to="/OfferGoodsDirect"
              className="popup-btn-link"
              onClick={handleClose}
            >
              Direct Order
            </Link>
          </div>
          <div className="mt-2 popup-link-div">
            <Link
              to="/VehicleRequestOnlineAuction"
              className="popup-btn-link"
              onClick={handleClose}
            >
              Online Auction
            </Link>
          </div>
        </div>
      </Modal> */}
    </div>
  );
}

export default App;
