import React, { useContext, useState } from "react";
import AbhayHeader from "./AbhayHeader";
import Sidebar from "./Sidebar";
import { AppContext } from "../context/user/AppContext";

const TermsAndConditions = () => {
const {mainClass}= useContext(AppContext);

  return (
    <>
      {/* <Sidebar/>
         <AbhayHeader/> */}
      <main className={"main-section-TermsAndConditions "+mainClass} id="cxMain">
        <div className="T-C mt-4">
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita,
            mollitia facere animi quo ullam accusantium. Error, eaque. A,
            delectus facilis et quia laborum corrupti corporis omnis nemo amet
            ea ab doloremque pariatur necessitatibus magni! Facilis blanditiis
            architecto dolorem quos dolores perferendis, reiciendis ratione quis
            unde hic tempora, repudiandae voluptate exercitationem. Deleniti,
            natus voluptatibus? Vel, molestiae iure. Dolores, soluta corporis
            pariatur eius quasi cum, distinctio nemo vero quae voluptas libero
            laudantium facere obcaecati dignissimos. Repudiandae nulla neque
            corporis tempore similique quam eum dolores consequatur libero
            perferendis ad molestias, optio repellendus voluptates qui sed esse
            animi debitis? Asperiores hic qui necessitatibus adipisci.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Expedita
            fugit aliquid ipsam quibusdam. Iusto veniam, alias ducimus quia est
            nemo maiores modi repudiandae, quisquam nesciunt nobis nisi
            consequatur cupiditate itaque eum odio molestiae molestias atque.
            Possimus adipisci officia officiis voluptas deleniti maiores
            obcaecati eligendi, animi sunt eum, culpa quas corrupti et expedita
            esse quos harum ut corporis doloribus laboriosam soluta at nobis?
            Vel earum voluptate architecto non deleniti id, sapiente rem, a iste
            accusamus, assumenda repellat totam. Excepturi, officiis autem
            dolore pariatur velit reprehenderit quo est atque dolorum hic quos.
            Modi tempore dolorem corporis doloremque provident quos tenetur,
            vitae at ex enim ab aut omnis! Id dolorem numquam sequi, optio
            mollitia cum esse ducimus voluptatum enim minus recusandae
            doloremque quas blanditiis molestiae debitis non. Quod corporis
            dolor esse quasi doloremque amet. Sequi exercitationem assumenda,
            iste sit, earum suscipit vero in tempora accusamus itaque illum quia
            dicta ea maxime. Aliquid, ea!
          </p>
        </div>
      </main>
    </>
  );
};

export default TermsAndConditions;
