import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import ic_remove from "../assets/images/ic_remove.svg";
import CountrySelect from "./CountrySelect";
import CitySelect from "./CitySelect";
import DragAndDrop from "./DragAndDropForTransporter";
import ic_camera from "../assets/images/ic_camera.svg";
import { postWithAuthCallWithErrorResponse } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import { AppContext } from "../context/user/AppContext";
import default_profile from "../assets/images/logo_default_bg.svg";
const AddTransporter = () => {
  const { customerData, mainClass, setApiCalling } = useContext(AppContext);
  const params = useParams();
  const id = params.id;
  const { state, setState } = useContext(AppContext);
  console.log(state);
  const [companyTypes, setCompanyTyes] = useState([]);
  const [country, setCountry] = useState("");
  const [errMsg, setErrMsg] = useState({
    profile_picture: "",
    company_type: "",
    company_name: "",
    contact_person: "",
    total_fleet_size: "",
    country: "",
    contact_person_responsibility: "",
    contact_person_phone: "",
    contact_person_email: "",
    password: "",
    country: "",
    region: "",
    city: "",
    phone_no: "",
    alternate_phone: "",
    email: "",
    po_number: "",
    tn_document: "",
    grade_certificate: "",
    business_license: "",
  });
  useEffect(() => {
    sessionStorage.setItem("transporterData", JSON.stringify({ ...state }));
  }, [state]);

  const getCompanyTypes = () => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.COMPANY_TYPE_DROPDOWN,
      JSON.stringify({ ...customerData })
    )
      .then((res) => {
        if (res.json.result) {
          setCompanyTyes(res.json.company_type);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getCompanyTypes();

    return () => {};
  }, []);

  const navigate = useNavigate();

  const continueNext = async (e) => {
    e.preventDefault();

    if (
      !state.profile_picture ||
      !state.company_type ||
      !state.company_name ||
      !state.contact_person ||
      !state.contact_person_responsibility ||
      !state.contact_person_phone ||
      !state.contact_person_email ||
      !state.total_fleet_size ||
      (!state.password && !id) ||
      !state.country ||
      !state.region ||
      !state.city ||
      !state.phone_no ||
      // !state.alternate_phone ||
      !state.email ||
      !state.po_number ||
      !state.tn_document ||
      !state.grade_certificate ||
      !state.business_license
    ) {
      if (!state.profile_picture) {
        setErrMsg({ ...errMsg, profile_picture: "Profile picture is requied" });
        window.scrollTo(0, 0);
        return;
      }
      if (!state.company_type) {
        setErrMsg({ ...errMsg, company_type: "Company Type is required" });
        window.scrollTo(0, 0);
        return;
      }
      if (!state.company_name) {
        setErrMsg({ ...errMsg, company_name: "Company Name is required" });
        window.scrollTo(0, 0);
        return;
      }
      if (!state.contact_person) {
        setErrMsg({
          ...errMsg,
          contact_person: "Contact Person name is required",
        });
        window.scrollTo(200, 200);
        return;
      }
      if (!state.contact_person_responsibility) {
        setErrMsg({
          ...errMsg,
          contact_person_responsibility:
            "Contact Person Responsibility is required",
        });
        window.scrollTo(240, 240);
        return;
      }
      if (!state.contact_person_phone) {
        setErrMsg({
          ...errMsg,
          contact_person_phone: "Contact Person Phone is required",
        });
        window.scrollTo(260, 260);
        return;
      }
      if (!state.contact_person_email) {
        setErrMsg({
          ...errMsg,
          contact_person_email: "Contact Person Email ID is required",
        });
        window.scrollTo(280, 280);
        return;
      }

      if (!state.total_fleet_size) {
        setErrMsg({
          ...errMsg,
          total_fleet_size: "Total Fleet Size is required",
        });
        window.scrollTo(320, 320);
        return;
      }
      if (!state.password && !id) {
        setErrMsg({ ...errMsg, password: "Password is required" });
        window.scrollTo(320, 320);
        return;
      }
      if (!state.country) {
        setErrMsg({ ...errMsg, country: "Country is required" });
        window.scrollTo(0, 600);
        return;
      }
      if (!state.region) {
        setErrMsg({ ...errMsg, region: "Region is required" });
        window.scrollTo(0, 600);
        return;
      }
      if (!state.city) {
        setErrMsg({ ...errMsg, city: "City is required" });
        window.scrollTo(0, 700);
        return;
      }
      if (!state.phone_no) {
        setErrMsg({ ...errMsg, phone_no: "Phone number is required" });
        window.scrollTo(0, 700);
        return;
      }
      // if (!state.alternate_phone) {
      //   setErrMsg({
      //     ...errMsg,
      //     alternate_phone: "Alternate Phone number is required",
      //   });
      //   window.scrollTo(0, 800);
      //   return;
      // }
      if (!state.email) {
        setErrMsg({ ...errMsg, email: "Email ID is required" });
        window.scrollTo(0, 800);
        return;
      }

      if (!state.po_number) {
        setErrMsg({ ...errMsg, po_number: "P O Number is required" });
        window.scrollTo(0, 800);
        return;
      }
      if (!state.tn_document) {
        setErrMsg({ ...errMsg, tn_document: "Tin Document is required" });
        window.scrollTo(0, 1500);
        return;
      }
      if (!state.grade_certificate) {
        setErrMsg({
          ...errMsg,
          grade_certificate: "Grade Certificate is required",
        });
        window.scrollTo(0, 1500);
        return;
      }
      if (!state.business_license) {
        setErrMsg({
          ...errMsg,
          business_license: "Business License is required",
        });
        window.scrollTo(0, 1500);
        return;
      }
    } else {
      if (!validateEmail(state.email)) {
        setErrMsg({
          ...errMsg,
          email: "Please Enter Email ID is proper fomat",
        });
        window.scrollTo(0, 800);
        return;
      }
      if (!validateEmail(state.contact_person_email)) {
        setErrMsg({
          ...errMsg,
          contact_person_email: "Please Enter Email ID is proper fomat",
        });
        window.scrollTo(300, 300);
        return;
      }

      navigate("/AddTransporterSecond");
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  return (
    <main
      className={`main-section-AddVehicle`}
      id="cxMain"
      style={{ marginLeft: "20px" }}
    >
      <div className="main-body-AddVehicle">
        {/* Progress bar block */}
        <section className="progress-block">
          <div className="row">
            <div className="col-12">
              <div className="main-content p-4 row">
                <div className="left col-lg-9 col-md-8">
                  <h4 className="" id="heading-addVehicles">
                    Add Delivery System
                  </h4>
                  <div
                    className="progress"
                    style={{ height: "8px", margin: "10px" }}
                  >
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "3%" }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
                <div className="right col-lg-3 col-md-4 mt-4 ">
                  <p className="progress-status">
                    Delivery System Details (1/2)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Vehicle Info Add */}
        <section className="main-vehicle-info mt-4">
          <div className="content">
            <div className="row">
              <div className="col-12">
                <div>
                  {/* <label htmlFor="file" className="me-4"> Cargo Delivery</label>
                  <input type="checkbox" className="form-check-input" onChange={(e) => { e.target.checked ? setState({ ...state, user_role: "cargo-delivery" }) : setState({ ...state, user_role: "transporter" }) }} /> */}
                </div>
                <div
                  className="cx-profile-pic position-relative"
                  style={{
                    textAlign: "center",
                    margin: "40px 0",
                  }}
                >
                  <label htmlFor="file">
                    <img
                      className="cx-pdefault"
                      src={
                        !state.profile_picture && state.profile_picture
                          ? default_profile
                          : state.profile_picture &&
                            state.profile_picture.includes("base64")
                          ? state.profile_picture
                          : ApiConfig.BASE_URL_FOR_IMAGES +
                            state.profile_picture
                      }
                      style={{
                        display: "inline-block",
                        height: "120px",
                        width: "120px",
                        borderRadius: "50%",
                      }}
                    />
                  </label>
                  <div
                    className="camera-icon"
                    style={{
                      position: "absolute",
                      top: "75%",
                      transform: "translate(-50%, 30%)",
                      left: "50%",
                    }}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      id="file"
                      onChange={(e) => {
                        setErrMsg({ ...errMsg, profile_picture: "" });
                        let reader = new FileReader();
                        reader.onload = function (e) {
                          setState({
                            ...state,
                            profile_picture: reader.result,
                          });
                        };
                        reader.readAsDataURL(e.target.files[0]);
                      }}
                    ></input>
                    <label
                      htmlFor="file"
                      className="upload-profile-image"
                      style={{ cursor: "pointer" }}
                    >
                      <img className="cx-camera" src={ic_camera} /> Upload Logo
                    </label>
                  </div>
                </div>
                {errMsg.profile_picture.length > 0 && (
                  <div className="text-center text-danger">
                    <span> {errMsg.profile_picture}</span>
                  </div>
                )}
                <div className="row">
                  <div className="col-lg-3 col-md-6">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Company Type</label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        value={state.companyType}
                        onChange={(e) => {
                          setErrMsg({ ...errMsg, company_type: "" });
                          setState({ ...state, company_type: e.target.value });
                        }}
                      >
                        <option>Select Company Type</option>
                        {companyTypes.map((companyType, index) => {
                          return companyType.company_type_id ==
                            state.company_type ? (
                            <option
                              selected
                              value={companyType.company_type_id}
                              key={"companyType" + index}
                            >
                              {companyType.company_type_name}
                            </option>
                          ) : (
                            <option
                              value={companyType.company_type_id}
                              key={"companyType" + index}
                            >
                              {" "}
                              {companyType.company_type_name}
                            </option>
                          );
                        })}
                      </select>
                      {errMsg.company_type.length > 0 && (
                        <div className="text-center text-danger">
                          <span> {errMsg.company_type}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Company Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={state.company_name}
                        onChange={(e) => {
                          setErrMsg({ ...errMsg, company_name: "" });
                          setState({ ...state, company_name: e.target.value });
                        }}
                      />
                      {errMsg.company_name.length > 0 && (
                        <div className="text-center text-danger">
                          <span> {errMsg.company_name}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Contact Person</label>
                      <input
                        type="tel"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={state.contact_person}
                        onChange={(e) => {
                          setErrMsg({ ...errMsg, contact_person: "" });
                          setState({
                            ...state,
                            contact_person: e.target.value,
                          });
                        }}
                      />
                      {errMsg.contact_person.length > 0 && (
                        <div className="text-center text-danger">
                          <span> {errMsg.contact_person}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">
                        Contact Person Responsibility
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={state.contact_person_responsibility}
                        onChange={(e) => {
                          setErrMsg({
                            ...errMsg,
                            contact_person_responsibility: "",
                          });
                          setState({
                            ...state,
                            contact_person_responsibility: e.target.value,
                          });
                        }}
                      />
                      {errMsg.contact_person_responsibility.length > 0 && (
                        <div className="text-center text-danger">
                          <span> {errMsg.contact_person_responsibility}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">
                        Contact Person Phone
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        maxLength={10}
                        value={state.contact_person_phone}
                        onChange={(e) => {
                          setState({
                            ...state,
                            contact_person_phone: e.target.value,
                          });
                          const re = /^[0-9]+$/;
                          if (!isNaN(e.target.value)) {
                            setErrMsg({ ...errMsg, contact_person_phone: "" });
                            setState({
                              ...state,
                              contact_person_phone: e.target.value,
                            });
                          } else {
                            setErrMsg({
                              ...errMsg,
                              contact_person_phone:
                                "Mobile number does not contains a alphabet",
                            });
                          }
                        }}
                      />
                      {errMsg.contact_person_phone.length > 0 && (
                        <div className="text-center text-danger">
                          <span> {errMsg.contact_person_phone}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">
                        Contact Person Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={state.contact_person_email}
                        onChange={(e) => {
                          setErrMsg({ ...errMsg, contact_person_email: "" });
                          setState({
                            ...state,
                            contact_person_email: e.target.value,
                          });
                        }}
                      />
                      {errMsg.contact_person_email.length > 0 && (
                        <div className="text-center text-danger">
                          <span> {errMsg.contact_person_email}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">
                        Total Fleet Size
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        aria-describedby="emailHelp"
                        value={state.total_fleet_size}
                        onChange={(e) => {
                          setErrMsg({ ...errMsg, total_fleet_size: "" });
                          setState({
                            ...state,
                            total_fleet_size: e.target.value,
                          });
                        }}
                      />
                    </div>
                    {errMsg.total_fleet_size.length > 0 && (
                      <div className="text-center text-danger">
                        <span> {errMsg.total_fleet_size}</span>
                      </div>
                    )}
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Login Password</label>
                      <input
                        type="password"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={state.password}
                        onChange={(e) => {
                          setErrMsg({ ...errMsg, password: "" });
                          setState({ ...state, password: e.target.value });
                        }}
                      />
                      {errMsg.password.length > 0 && (
                        <div className="text-center text-danger">
                          <span> {errMsg.password}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Address */}

        <section className="main-vehicle-info  mt-4">
          <div className="content">
            <div className="row">
              <div className="col-12">
                <h5 className="card-heading">Address</h5>
                <hr />

                <div className="row">
                  <div className="col-lg-3 col-md-6">
                    <CountrySelect
                      setCountry={(country) => {
                        console.log(country);
                        setErrMsg({ ...errMsg, country: "" });
                        setState({ ...state, country: country.value });
                        setCountry(country.isoCode);
                      }}
                      country={state.country}
                    />
                    {errMsg.country.length > 0 && (
                      <div className="text-center text-danger">
                        <span> {errMsg.country}</span>
                      </div>
                    )}
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Region</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={state.region}
                        onChange={(e) => {
                          setErrMsg({ ...errMsg, region: "" });
                          setState({ ...state, region: e.target.value });
                        }}
                      />
                      {errMsg.region.length > 0 && (
                        <div className="text-center text-danger">
                          <span> {errMsg.region}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <CitySelect
                      country={country}
                      setCity={(city) => {
                        setErrMsg({ ...errMsg, city: "" });
                        setState({ ...state, city: city.value });
                      }}
                      city={state.city}
                    />
                    {errMsg.city.length > 0 && (
                      <div className="text-center text-danger">
                        <span> {errMsg.city}</span>
                      </div>
                    )}
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Phone</label>
                      <input
                        type="tel"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        maxLength={10}
                        value={state.phone_no}
                        onChange={(e) => {
                          if (!isNaN(e.target.value)) {
                            setState({ ...state, phone_no: e.target.value });
                            setErrMsg({ ...errMsg, phone_no: "" });
                          } else {
                            setErrMsg({
                              ...errMsg,
                              phone_no:
                                "Mobile number does not contains a alphabet",
                            });
                          }
                        }}
                      />
                      {errMsg.phone_no.length > 0 && (
                        <div className="text-center text-danger">
                          <span> {errMsg.phone_no}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">
                        Alternate Phone
                      </label>
                      <input
                        type="tel"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        maxLength={10}
                        value={state.alternate_phone}
                        onChange={(e) => {
                          if (!isNaN(e.target.value)) {
                            setState({
                              ...state,
                              alternate_phone: e.target.value,
                            });
                            setErrMsg({ ...errMsg, alternate_phone: "" });
                          } else {
                            setErrMsg({
                              ...errMsg,
                              alternate_phone:
                                "Mobile number does not contains a alphabet",
                            });
                          }
                        }}
                      />
                      {/* {errMsg.alternate_phone.length > 0 && (
                        <div className="text-center text-danger">
                          <span> {errMsg.alternate_phone}</span>
                        </div>
                      )} */}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={state.email}
                        onChange={(e) => {
                          setErrMsg({ ...errMsg, email: "" });
                          setState({ ...state, email: e.target.value });
                        }}
                      />
                      {errMsg.email.length > 0 && (
                        <div className="text-center text-danger">
                          <span> {errMsg.email}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">P.O.Box</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={state.po_number}
                        onChange={(e) => {
                          setErrMsg({ ...errMsg, po_number: "" });
                          setState({ ...state, po_number: e.target.value });
                        }}
                      />
                      {errMsg.po_number.length > 0 && (
                        <div className="text-center text-danger">
                          <span> {errMsg.po_number}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*  Documents */}

        <section className="documents-section">
          <div className="common-body mt-4 p-3">
            <div className="row">
              <div className="top-content mb-3">
                <h5 className="card-heading">Documents</h5>
                <hr />
              </div>
              <div className="col-lg-4 col-md-6">
                <label htmlFor="">TIN</label>
                <DragAndDrop
                  setFile={(file) => {
                    console.log(file);
                    setErrMsg({ ...errMsg, tn_document: "" });
                    let reader = new FileReader();
                    reader.onload = function (e) {
                      setState({ ...state, tn_document: reader.result });
                    };
                    reader.readAsDataURL(file);
                  }}
                  id="tn_document"
                />

                <div className="uploaded_file d-flex justify-content-between">
                  <label className="col-4 text-truncate">
                    {state.tn_document && state.tn_document}
                  </label>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setState({ ...state, tn_document: "" });
                    }}
                  >
                    <img src={ic_remove} alt="" />
                  </a>
                </div>
                {errMsg.tn_document.length > 0 && (
                  <div className="text-center text-danger">
                    <span> {errMsg.tn_document}</span>
                  </div>
                )}
              </div>
              <div className="col-lg-4 col-md-6">
                <label htmlFor="">Grade Certified By Transport Minister</label>
                <DragAndDrop
                  setFile={(file) => {
                    setErrMsg({ ...errMsg, grade_certificate: "" });
                    let reader = new FileReader();
                    reader.onload = function (e) {
                      setState({ ...state, grade_certificate: reader.result });
                    };
                    reader.readAsDataURL(file);
                  }}
                  id="grade_certificate"
                />
                <div className="uploaded_file d-flex justify-content-between">
                  <label className=" col-4 text-truncate">
                    {state.grade_certificate}
                  </label>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setState({ ...state, grade_certificate: "" });
                    }}
                  >
                    <img src={ic_remove} alt="" />
                  </a>
                </div>
                {errMsg.grade_certificate.length > 0 && (
                  <div className="text-center text-danger">
                    <span> {errMsg.grade_certificate}</span>
                  </div>
                )}
              </div>
              <div className="col-lg-4 col-md-6">
                <label htmlFor="">Business License</label>
                <DragAndDrop
                  setFile={(file) => {
                    setErrMsg({ ...errMsg, business_license: "" });
                    let reader = new FileReader();
                    reader.onload = function (e) {
                      setState({ ...state, business_license: reader.result });
                    };
                    reader.readAsDataURL(file);
                  }}
                  id="business_license"
                />
                <div className="uploaded_file d-flex justify-content-between">
                  <label className="col-4 text-truncate">
                    {state.business_license}
                  </label>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setState({ ...state, business_license: "" });
                    }}
                  >
                    <img src={ic_remove} alt="" />
                  </a>
                </div>
                {errMsg.business_license.length > 0 && (
                  <div className="text-center text-danger">
                    <span> {errMsg.business_license}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="fix-bottom-bar d-flex justify-content-between align-items-center mt-4">
        <div className="left"></div>
        <div className="right">
          <a href="">
            <button type="submit" className="cancel-btn">
              Cancel
            </button>
          </a>
          <Link to="#" onClick={continueNext}>
            <button type="submit" className="continue-btn">
              Continue
            </button>
          </Link>
          {/* <div className="login-btn">
                <button
                  type="submit"
                  
                  className="btn  col-sm-12 col-md-12 col-lg-12"
                >
                  <span style={{ color: "white" }}> Continue</span>
                </button>
              </div> */}
        </div>
      </div>
    </main>
  );
};

export default AddTransporter;
