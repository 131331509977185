// const BASE_URL = "http://abhay-logistics.redbytes.in/api/transporter/";
const CLIENT_BASE_URL = "https://app.abaylogistics.com/api/transporter/";

const ApiConfig = {
  // BASE_URL,
  CLIENT_BASE_URL,
  BASE_URL_FOR_IMAGES: "https://app.abaylogistics.com/",
  /* Transporter API url Start*/
  TRANSPORTER_LOGIN_API: CLIENT_BASE_URL + "login",
  TRANSPORTER_CONTACT_US_API: CLIENT_BASE_URL + "contactus",
  COMPANY_TYPE_DROPDOWN: CLIENT_BASE_URL + "dropdown/companytype",
  ADMIN_DROPDOWN: CLIENT_BASE_URL + "dropdown",
  ADD_TRANSPORTER: CLIENT_BASE_URL + "tpregister",
  AddVehicleDropdownlist: CLIENT_BASE_URL + "vehicle/dropdownlist",
  ADDOWNER: CLIENT_BASE_URL + "owner/add",
  AddVehicle: CLIENT_BASE_URL + "vehicle/add",
  OWNER_LIST: CLIENT_BASE_URL + "owner/list",
  DRIVER_LIST: CLIENT_BASE_URL + "driver/list",
  VEHICLE_LIST: CLIENT_BASE_URL + "vehicle/list",
  DRIVER_DETAILS: CLIENT_BASE_URL + "driver/details",
  VEHICLE_DETAILS: CLIENT_BASE_URL + "vehicle/details",
  CHANGE_DRIVER_VEHICLE: CLIENT_BASE_URL + "driver/changedriver_vehicle",
  ADD_DRIVER: CLIENT_BASE_URL + "driver/add",
  ONLINE_AUCTION_OFFER_GOODS: CLIENT_BASE_URL + "vehiclerequest/onlineorders",
  ONGOING_FRIGHT: CLIENT_BASE_URL + "freights/ongoing",
  UPCOMMING_FRIGHT: CLIENT_BASE_URL + "freights/upcoming",
  COMPLETED_FRIGHT: CLIENT_BASE_URL + "freights/completed",
  DRIRECT_ORDER_OFFER_GOODS: CLIENT_BASE_URL + "vehiclerequest/directorders",
  GOODS_DETAILS: CLIENT_BASE_URL + "load/details",
  DIRECT_ORDERS_OFFERED_VEHICLES: CLIENT_BASE_URL + "vehicleoffer/directorders",
  DIRECT_ORDERS_OFFERED_VEHICLES_ONLINE: CLIENT_BASE_URL + "vehicleoffer/onlineorders",
  DIRECT_ORDERS_ORDER_CONFIRMATION_: CLIENT_BASE_URL + "orderconfirmation",
  DIRECT_ORDERS_ORDER_CONFIRMATION_Online_:
    CLIENT_BASE_URL + "orderconfirmation/online",
  VIEWSPECIFICATION: CLIENT_BASE_URL + "vehiclerequest/viewspecification",
  BLOCK_VEHICLE: CLIENT_BASE_URL + "vehicle/block",
  BLOCK_DRIVER: CLIENT_BASE_URL + "driver/block",
  DIRECT_ORDER_OFFER_GOODS_VEHICLE_REJECT: CLIENT_BASE_URL + "vehiclerequest/reject",
  DRIRECT_ORDER_OFFER_GOODS_VEHICLE_ACCEPT:
    CLIENT_BASE_URL + "vehiclerequest/acceptweb",
  DRIRECT_ORDER_OFFER_VEHICLES_VEHICLE_DETAILS:
    CLIENT_BASE_URL + "vehicleoffer/vehicledetails",
  ONLINE_AUCTION_OFFERGOODS_MAKE_BID: CLIENT_BASE_URL + "bid/add",
  PROFILE: CLIENT_BASE_URL + "profile",
  DASHBOARD: CLIENT_BASE_URL + "dashboard",
  NOTIFICATION: CLIENT_BASE_URL + "notification",
  DELETE_NOTIFICATION: CLIENT_BASE_URL + "notification/bulk_delete",
  VEHICLE_LIST_BY_LOADID: CLIENT_BASE_URL + "vehicleoffer/vehicledetails",
  ORDER_CONFIRMATION_ORDER_DETAILS:
    CLIENT_BASE_URL + "orderconfirmation/vehicledetails",
  LIVE_TRACKING: CLIENT_BASE_URL + "livetrack",
  INITIATE_FRIGHT: CLIENT_BASE_URL + "freights/initiate",
  FORGET_PASSWORD: CLIENT_BASE_URL + "forgotpassword",
  RESET_PASSWORD: CLIENT_BASE_URL + "resetpassword",
  TARRIF_EXPORT_LIST: CLIENT_BASE_URL + "tariffexport",
  TARRIF_IMPORT_LIST: CLIENT_BASE_URL + "tariffimport",
  /* Transporter API url End*/
};
export default ApiConfig;
