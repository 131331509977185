import React, { useContext } from "react";
import { Link } from "react-router-dom";
import arrow from "../../assets/images/ic_dropdown_arrow.svg";
import { AppContext } from "../../context/user/AppContext";
export const Reports = () => {
  const { mainClassB } = useContext(AppContext);

  return (
    <>
      <main className={` main-section-reports  ${mainClassB}`} id="cxMainB">
        {/* Report tabs */}

        <div class="d-flex  ">
          <div
            class="nav flex-column nav-pills reports-tab"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            <h5 className="tab-heading-reports">Select Reports Type</h5>
            <button
              class="nav-link active"
              id="v-pills-home-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-home"
              type="button"
              role="tab"
              aria-controls="v-pills-home"
              aria-selected="true"
            >
              Vehicle Tracking & Tracing
              {/* <img src={arrow} alt="" className="arrow-orange" /> */}
            </button>
            <button
              class="nav-link"
              id="v-pills-profile-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-profile"
              type="button"
              role="tab"
              aria-controls="v-pills-profile"
              aria-selected="false"
            >
              Payment Status
              {/* <img src={arrow} alt="" className="arrow-orange" /> */}
            </button>
            <button
              class="nav-link"
              id="v-pills-profile-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-profile"
              type="button"
              role="tab"
              aria-controls="v-pills-profile"
              aria-selected="false"
            >
              Drivers Report
              {/* <img src={arrow} alt="" className="arrow-orange" /> */}
            </button>
            <button
              class="nav-link"
              id="v-pills-profile-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-profile"
              type="button"
              role="tab"
              aria-controls="v-pills-profile"
              aria-selected="false"
            >
              Vehicle Working With Abay
              {/* <img src={arrow} alt="" className="arrow-orange" /> */}
            </button>
            <button
              class="nav-link "
              id="v-pills-messages-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-messages"
              type="button"
              role="tab"
              aria-controls="v-pills-messages"
              aria-selected="false"
            >
              History
              {/* <img src={arrow} alt="" className="arrow-orange" /> */}
            </button>
          </div>
          {/* <div class="tab-content " id="v-pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="v-pills-home"
              role="tabpanel"
              aria-labelledby="v-pills-home-tab"
            >
              <div className="  ">
                <section className="report-body">
                  <div className=" ">
                    <div className="reports-body-section ">
                      <h4 className="reports-card-heading">
                        Configure Reports
                      </h4>
                      <hr style={{ color: "#F6F2EF" }} />
                      <div className="col">
                        <div className="form-group mt-4">
                          <label htmlFor="exampleInputEmail1">
                            Select Transporter
                          </label>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            onChange={(e) => {
                              setErrMsg({ ...errMsg, report_type: "" });
                              setReportConfigure({
                                ...reportConfigure,
                                report_type: e.target.value,
                              });
                            }}
                          >
                            {reportTypes.map((reportType, index) => {
                              return index == 0 ? (
                                <option selected>
                                  {reportType.report_type_name}
                                </option>
                              ) : (
                                <option>{reportType.report_type_name}</option>
                              );
                            })}
                          </select>
                          {errMsg.report_type.length > 0 && (
                            <div className="text-center text-danger">
                              <span> {errMsg.report_type}</span>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">
                          For which period do you need a report?
                        </label>

                        <div class="row row-cols-2">
                          <div class="col">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="exampleRadios"
                                id="exampleRadios1"
                                value="option1"
                                checked
                              />
                              <label
                                class="form-check-label"
                                for="exampleRadios1"
                              >
                                Last Month
                              </label>
                            </div>
                          </div>
                          <div class="col">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="exampleRadios"
                                id="exampleRadios1"
                                value="option1"
                                checked
                              />
                              <label
                                class="form-check-label"
                                for="exampleRadios1"
                              >
                                Last 3 Months
                              </label>
                            </div>
                          </div>
                          <div class="col">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="exampleRadios"
                                id="exampleRadios1"
                                value="option1"
                                checked
                              />
                              <label
                                class="form-check-label"
                                for="exampleRadios1"
                              >
                                Last 6 Months
                              </label>
                            </div>
                          </div>
                          <div class="col">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="exampleRadios"
                                id="exampleRadios1"
                                value="option1"
                                checked
                              />
                              <label
                                class="form-check-label"
                                for="exampleRadios1"
                              >
                                Last 1 Year
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">
                          For which period do you need a report?
                        </label>

                        <div class="row row-cols-2">
                          <div class="col">
                            <div className="form-group mt-4">
                              <label htmlFor="exampleInputEmail1">
                                From Date
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                onChange={(e) => {
                                  setErrMsg({ ...errMsg, from_date: "" });
                                  setReportConfigure({
                                    ...reportConfigure,
                                    from_date: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            {errMsg.from_date.length > 0 && (
                            <div className="text-center text-danger">
                              <span> {errMsg.from_date}</span>
                            </div>
                             )} 
                          </div>
                          <div className="col">
                            <div className="form-group mt-4">
                              <label htmlFor="exampleInputEmail1">
                                To Date
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                onChange={(e) => {
                                  setErrMsg({ ...errMsg, to_date: "" });
                                  setReportConfigure({
                                    ...reportConfigure,
                                    to_date: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            {errMsg.to_date.length > 0 && (
                            <div className="text-center text-danger">
                              <span> {errMsg.to_date}</span>
                            </div>
                             )} 
                          </div>
                        </div>
                      </div>

                      <div>
                        <Link
                          to="#"
                          className="report-btn"
                          onClick={onGenerateReport}
                        >
                          Generate
                        </Link>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div> */}
          <div class="tab-content " id="v-pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="v-pills-home"
              role="tabpanel"
              aria-labelledby="v-pills-home-tab"
            >
              <div className="  ">
                <section className="report-body">
                  <div className=" ">
                    <div className="reports-body-section ">
                      <h4 className="reports-card-heading">
                        Configure Reports
                      </h4>
                      <hr style={{ color: "#F6F2EF" }} />
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">Enter Email</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">Report Title</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">From Date</label>
                        <input
                          type="date"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">To Date</label>
                        <input
                          type="date"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                      <div>
                        <Link to="#" className="report-btn">
                          Generate
                        </Link>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="v-pills-profile"
              role="tabpanel"
              aria-labelledby="v-pills-profile-tab"
            >
              {/* profile */}

              <div className="  ">
                <section className="report-body">
                  <div className=" ">
                    <div className="reports-body-section ">
                      <h4 className="reports-card-heading">
                        Configure Reports
                      </h4>
                      <hr />
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">Report Title</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">From Date</label>
                        <input
                          type="date"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">To Date</label>
                        <input
                          type="date"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                      <div>
                        <Link to="#" className="report-btn">
                          Generate
                        </Link>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="v-pills-messages"
              role="tabpanel"
              aria-labelledby="v-pills-messages-tab"
            >
              <div className="  ">
                <section className="report-body">
                  <div className=" ">
                    <div className="reports-body-section ">
                      <h4 className="reports-card-heading">
                        Configure Reports
                      </h4>
                      <hr />
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">Report Title</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">From Date</label>
                        <input
                          type="date"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">To Date</label>
                        <input
                          type="date"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                      <div>
                        <Link to="#" className="report-btn">
                          Generate
                        </Link>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              {/* ........ */}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
