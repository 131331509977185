import React, { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import ApiConfig from "../api/ApiConfig";
import { PostCallWithErrorResponse } from "../api/ApiServices";
import ic_mail from "../assets/images/ic_mail.svg";
import { AppContext } from "../context/user/AppContext";
import AbhayHeader from "./AbhayHeader";
import LoadingBar from "react-top-loading-bar";
import Sidebar from "./Sidebar";

const ContactUs = () => {

  const [state, setState] = useState({ text: "" })
  const { customerData } = useContext(AppContext)
  const navigate = useNavigate()
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    setProgress(100)
  }, [])
  const handleSubmit = () => {
    setProgress(50)
    PostCallWithErrorResponse(ApiConfig.TRANSPORTER_CONTACT_US_API,
      { ...state, ...customerData }
    )
      .then((data) => {
        setProgress(100)
        if (data.json.result) {
          swal({
            title: "sucess",
            text: data.json.message,
            icon: "success",
            button: true,
          }).then(() => {
            navigate('/Dashboard')
          })


        }

        console.log(data.json);


      })
      .catch((error) => {
        console.log("api response", error);

      });

  }






  return (





    <>
      {/* <Sidebar/>
         <AbhayHeader/> */}
      <LoadingBar
        color="red"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <main className="main-body-contactUs" >
        <div className="contactUs" >
          <div className="contact-us d-flex flex-column align-items-center flex-xl-row flex-md-column flex-sm-column flex-xs-column justify-content-around ">
            {/* contact left section */}
            <section className="left-section-contact  w-100 p-2">
              <h3 className="left-heading">Abay Logistics</h3>
              <p className="left-paragraph">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam
                voluptas pariatur ipsa error numquam commodi
              </p>
              <div className="email">
                <img src={ic_mail} alt="" /> <span className="left-paragraph"> abaylogistics@gmail.com</span>
              </div>

            </section>
            {/* contact right section */}
            <section className="right-section-contact p-2 w-100">
              <h3 className="get-in-touch">Get In Touch With Us</h3>
              {/*   <div className="form-group mt-4">
              <label htmlFor="exampleInputEmail1">Email</label>
              <input
                type="email"

                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
            </div> */}
              {/* <div className="form-group mt-4">
              <label htmlFor="exampleInputEmail1">Subject</label>
              <input
                type="text"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
            </div> */}

              <div className="form-group mt-4">
                <label htmlFor="exampleFormControlTextarea1">Message</label>
                <textarea
                  value={state.text}
                  onChange={(e) => setState({ ...state, text: e.target.value })}
                  className="form-control text-area"
                  id="exampleFormControlTextarea1"
                  rows="3"
                ></textarea>
              </div>
              <div className="button mt-4">
                <a href="#" onClick={handleSubmit} className="continue-btn">Continue</a>
              </div>
            </section>
          </div>
        </div>
      </main>





    </>
  );
};

export default ContactUs;
