import { Country } from "country-state-city";

import Select from "react-select";

export default function CountrySelect({ setCountry, country }) {
  let options = Country.getAllCountries().map((country) => {
    return {
      value: country.name,
      label: country.name,
      isoCode: country.isoCode,
    };
  });

  return (
    <div className="form-group mt-4">
      <label htmlFor="exampleInputEmail1">Country</label>

      <Select options={options} onChange={setCountry} defaultValue={country} />
    </div>
  );
}
